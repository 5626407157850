import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Toast } from "react-bootstrap";

const AlertView = (props) => {
    const [showMessage, setShowMessage] = useState(props.message);
    useEffect(()=>{
        console.log(props);
    })
    return (
        <div
            style={{
                position: "absolute",
                top: 20,
                right: 10,
                zIndex: 999,
                backgroundColor: "#00A884"
            }}
        >
            <Toast style={ { backgroundColor:"#04A9F5", color:"white"}}
                onClose={() => {
                    console.log(false);
                    props.onClose();
                }}
                show={true}
                delay={3000}
                autohide
            >
                <Toast.Header className="table" closeButton={false}>
                    <strong className="mr-auto">Alert !!</strong>
                </Toast.Header>
                <Toast.Body className="table">{showMessage}</Toast.Body>
            </Toast>
        </div>
    );
}

export default AlertView;
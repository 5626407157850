import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { Link, Route, Router, useNavigate, useParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import moment from "react-moment";

import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import AddUser from "../../components/add-user-form";
import {
  getRetailersListRequest,
  getSelectedUsersList,
} from "../../store/actions/onDataResultAction";
import NewUser from "../newuser/newuser";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";

const DRetailers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminList, setAdminList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [userData, setUserserData] = useState({});
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    getAdminList(10, 0);
  }, []);

  const getAdminList = async (limit, offset) => {
    setIsStoring(true);
    await dispatch(
      getRetailersListRequest(
        {
          role: 4,
          limit: limit,
          offset: offset,
          superUserId: localStorage.getItem("userId"),
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        setAdminList(response.result);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getAdminList(10, pageNumber - 1 + 10);
  };

  const getDateFormat = (date) => {
    console.log(date);
    return date;
  };

  const manageUserData = (roleId, userId) => {
    setUserserData({ roleId: roleId, userId: userId });
    // setShowAddUserForm(true);
    console.log("add new user");
    navigate("/adduser");
  };

  const userDeleteClicked = (userInfo) => {
    console.log("userDeleteClicked", userInfo.id);
    console.log("userDeleteClicked", userInfo);
  };

  const usereditClicked = (userInfo) => {
    setUserserData({
      roleId: "4",
      userId: userInfo.id,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
    });
    console.log("usereditClicked", userInfo);
    setShowAddUserForm(true);
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      {showAddUserForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-4">
              <AddUser
                onClose={() => {
                  console.log("Add User closed");
                  setShowAddUserForm(false);
                  getAdminList(10, 0);
                }}
                parentToChild={userData}
              />
            </div>
          </div>
        </div>
      )}
      <div className="container pt-2">
        <div className="row justify-content-around m-5">
          <div className="col-6">
            <h2 className="pageHeading">Retailers</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Retailers
                </li>
              </ol>
            </nav>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-purple-bg"
              onClick={() => manageUserData("4", "")}
            >
              + Add Retailer
            </button>
          </div>
        </div>

        <div className="row m-5">
          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Status</th>
                  <th scope="col">Wallet</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {adminList && adminList.length > 0 && (
                <tbody>
                  {adminList.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <td className="align-middle">
                          <p>{item.id}</p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.name}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.email}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.phone}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.status}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.walletBalance}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          {/* <button type="button" className="btn btn-white-bg">Update</button>
                        <button type="button" className="btn btn-white-bg">Delete</button> */}
                          <span className="actions">
                            <BiEdit onClick={() => usereditClicked(item)} />
                            {/* <BsFillTrashFill
                              className="delete-btn"
                              onClick={() => userDeleteClicked(item)}
                            /> */}
                          </span>
                        </td>
                        {/* <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => {
                              navigate(`/order-detail?orderId=${od.id}`);
                            }}
                          >
                            <AiFillEye />
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {
              adminList.length > 10
                ? <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={50}
                  pageRangeDisplayed={5}
                  onChange={(number) => handlePageChange(number)}
                />
                : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default DRetailers;

import { combineReducers } from "redux";
import { otpReducer } from "./otpReducer";
import { authReducer } from "./authReducer";

const rootReducer = combineReducers({
  otpReducer: otpReducer,
  authReducer: authReducer,
});

export default rootReducer;

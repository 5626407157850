import React, { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { CircleLoader } from "react-spinners";
import {
  vendorWalletChackUserRequest,
  vendorWalletUpdate,
} from "./vendorWalletAPIActions";
import { getWalletBalance } from "../../store/actions/onvBalanceDataResultAction";
import RingLoadingHud from "../../components/loadinghud";

const DstrAddWallet = () => {
  const dispatch = useDispatch();
  const [addwalletForm, setAddwalletFormForm] = useState({
    username: "",
  });
  const [userData, setUserData] = useState({});
  const [walletBalance, setWalletBalance] = useState([]);
  const [addAmountWalletForm, setAddAmountWalletForm] = useState({
    walletAmount: "",
    comments: "",
  });
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    getWalletBalanceReq();
  }, []);

  const getWalletBalanceReq = async () => {
    setIsStoring(true);
    await dispatch(
      getWalletBalance(
        {
          vId: localStorage.getItem("userId"),
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result != null) {
        setWalletBalance(response.result);
      }
    });
  };

  const handleUserChanges = (e) => {
    setAddwalletFormForm({ ...addwalletForm, username: e.target.value });
  };

  const checkUserDetails = async () => {
    if (addwalletForm.username.length === 0) {
      alert("please enter Mobile Number");
    } else {
      setIsStoring(true);
      await dispatch(
        vendorWalletChackUserRequest(
          {
            username: addwalletForm.username,
            superuserId: localStorage.getItem("userId"),
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response.status === true) {
          setUserData(response.result);
        } else {
          setUserData({});
          alert(response.message);
        }
      });
    }
  };

  const handleAddWalletChanges = (e) => {
    setAddAmountWalletForm({
      ...addAmountWalletForm,
      [e.target.name]: e.target.value,
    });
  };

  const addFundsToWallet = async () => {
    if (addAmountWalletForm.walletAmount.length === 0) {
      alert("please enter amount");
    } else if (addAmountWalletForm.comments.length === 0) {
      alert("please enter comments");
    } else if (walletBalance < addAmountWalletForm.walletAmount) {
      alert("Insufficient balance in your wallet");
    } else {
      setIsStoring(true);
      await dispatch(
        vendorWalletUpdate(
          {
            adminId: localStorage.getItem("userId"),
            vendorId: userData.id,
            walletAmount: addAmountWalletForm.walletAmount,
            comments: addAmountWalletForm.comments,
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          alert(response.message);
        }
      });
    }
  };

  const showUserDetails = () => {
    return (
      <div className="col">
        {/* <div className="row">
                <h6 className="table">User Name</h6>
                
            </div>
            <br />
            <div className="row">
                <h6 className="table">User Status</h6>
                
            </div> */}
        <h5 className="table" style={{ margin: "25px" }}>
          {userData.name} / {userData.status}
        </h5>
      </div>
    );
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      <div className="container pt-2">
        <div className="row justify-content-around m-5">
          <div>
            <h2 className="pageHeading">Funds to wallet</h2>
          </div>
          <div className="formcontainer" id="transfer">
            <div className="row justify-content-left m-3">
              <div className="row justify-content-left">
                <div className="col-3">
                  <label>Check User</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={addwalletForm.username}
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div>
                <div className="col-2">
                  <button
                    style={{ marginTop: "25px" }}
                    type="button"
                    className="btn btn-purple-bg"
                    onClick={() => checkUserDetails()}
                  >
                    Check User
                  </button>
                </div>
                {userData.status != null ? showUserDetails() : <div></div>}
              </div>
            </div>
            {userData.status == "ACTIVE" && (
              <div className="formcontainer" id="transfer">
                <div className="col-auto">
                  <div className="row justify-content-around">
                    <div className="col-3">
                      <label>Wallet Amount</label>
                      <input
                        type="number"
                        name="walletAmount"
                        className="form-control"
                        value={addwalletForm.walletAmount}
                        onChange={(e) => handleAddWalletChanges(e)}
                      />
                      <label>Comments</label>
                      <input
                        type="text"
                        name="comments"
                        className="form-control"
                        value={addwalletForm.comments}
                        onChange={(e) => handleAddWalletChanges(e)}
                      />
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <button
                          style={{ marginTop: "25px" }}
                          type="button"
                          className="btn btn-purple-bg"
                          onClick={() => addFundsToWallet()}
                        >
                          Add Amount
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DstrAddWallet;

import React from 'react';

const Categories = () => {
    return (
        <div>
            <h1>Categories page</h1>
        </div>
    );
};

export default Categories;
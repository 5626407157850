import React from "react";
import { RingLoader } from "react-spinners";

const RingLoadingHudPopUp = () => {
    return (
        <div>
            <div className="loaderParentDiv">
                <div className="loaderDivCenter">
                    <RingLoader color="#36d7b7" loading size={200} />
                </div>
            </div>
        </div>
    );
};

export default RingLoadingHudPopUp;
import React from 'react';

const SolvedTickets = () => {
    return (
        <div className="container pt-2">
            <div className="row justify-content-around m-5">
                <h2 className="pageHeading">Solved Tickets Page</h2>
            </div>
        </div>
    );
};

export default SolvedTickets;
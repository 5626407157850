import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import { slabListRequest } from "../../store/actions/onUserDataResultAction";
import UpdateBalance from "../../components/update-balance-form";
import AddSlab from "../../components/add-slab";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";

const Slabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slabsList, setslabsList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [showProductForm, setShowProductForm] = useState(false);
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    getVirtualBalance(10, 0);
  }, []);

  const getVirtualBalance = async (limit, offset) => {
    setIsStoring(true);
    await dispatch(
      slabListRequest({ limit: limit, offset: offset }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        setslabsList(response.result);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getVirtualBalance(10, pageNumber - 1 + 10);
  };

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      {showProductForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-8">
              <AddSlab
                onClose={() => {
                  setShowProductForm(false);
                  getVirtualBalance(10, 0);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="container pt-2">
        <div className="row justify-content-around m-5">
          <div className="col-6">
            <h2 className="pageHeading">Slabs</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Slabs
                </li>
              </ol>
            </nav>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-purple-bg"
              onClick={() => setShowProductForm(true)}
            >
              + Add Slab
            </button>
            {/* <button type="button" className="btn btn-white-bg">Update</button> */}
          </div>
        </div>

        <div className="row m-5">
          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Admin</th>
                  <th scope="col">Partner</th>
                  <th scope="col">Distributor</th>
                  <th scope="col">Retailer</th>
                </tr>
              </thead>
              {slabsList && slabsList.length > 0 && (
                <tbody>
                  {slabsList.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <th className="align-middle">
                          <p>{item.id}</p>
                        </th>
                        <td className="align-middle">
                          <p>
                            {item.name}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.owner}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.partner}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.distributor}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.retailer}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>

                        {/* <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => {
                              navigate(`/order-detail?orderId=${od.id}`);
                            }}
                          >
                            <AiFillEye />
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {
              slabsList.length > 10
                ? <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={50}
                  pageRangeDisplayed={5}
                  onChange={(number) => handlePageChange(number)}
                />
                : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Slabs;

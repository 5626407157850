import React, { useState } from "react";

import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegListAlt,
  FaSitemap,
  FaUserCircle,
  FaUserAltSlash,
  FaUserAstronaut,
  FaUsers,
  FaUserSecret,
} from "react-icons/fa";
import { TfiAngleDoubleRight, TfiAngleDoubleLeft, TfiHelp, TfiHelpAlt, TfiUser } from "react-icons/tfi";
import { TbRecharging } from "react-icons/tb";
import {
  AiFillDashboard,
  AiOutlineWallet,
  AiOutlineTransaction,
} from "react-icons/ai";
import { HiDocumentReport, HiOutlineUserCircle } from "react-icons/hi";
import { IoMdPricetag } from "react-icons/io";
import { MdAccountBalance } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import SidebarLink from "./sidebar-link";
import Login from "../pages/login/login";
import AuthService from "../utils/services/auth-service";
import { BiBookAdd, BiListCheck, BiUserCircle } from "react-icons/bi";
import { Container, Navbar } from "react-bootstrap";

let selctedMenu;
const Sidebar = ({ children }) => {
  let addressUrl = new URL(window.location.href);
  let pathName = addressUrl.pathname.split("/");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [subnav, setSubnav] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [subnavIndex, setSubnavIndex] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("authToken")
  );

  const toggle = () => setIsOpen(!isOpen);
  const showSubNav = (mainIndex) => {
    setSubnav(!subnav);
    setSubnavIndex(mainIndex);
  };

  const logout = async () => {
    await AuthService.logout();
    navigate("/login");
  };

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiFillDashboard />,
      subnav: [],
      roles: ["1", "2", "3", "4"],
    },
    {
      path: "/vbalance",
      name: "Virtual Balance",
      icon: <MdAccountBalance />,
      subnav: [],
      roles: ["1"],
    },
    {
      path: "",
      name: "Virtual Wallet",
      icon: <AiOutlineWallet />,
      subnav: [
        { path: "/vendoraddwallet", name: "Add Wallet", icon: <BiBookAdd /> },
        {
          path: "/vendorwallettransactions",
          name: "Wallet Transactions",
          icon: <AiOutlineTransaction />,
        },
      ],
      roles: ["1"],
    },

    {
      path: "/vwtransactions",
      name: "My Wallet",
      icon: <AiFillDashboard />,
      subnav: [],
      roles: ["3", "4"],
    },
    {
      path: "/drwallet",
      name: "Recharge Wallet",
      icon: <AiFillDashboard />,
      subnav: [],
      roles: ["3"],
    },
    {
      path: "/recharge",
      name: "Recharge",
      icon: <FaUserAlt />,
      subnav: [],
      roles: ["2", "4"],
    },
    {
      path: "/bbpszp",
      name: "BBPS",
      subnav: [],
      icon: <FaUserAlt />,
      roles: ["4"],
    },
    {
      path: "payments/pay-in",
      name: "Payment Links",
      icon: <FaRegListAlt />,
      subnav: [
        // {
        //   path: "payments/pay-in",
        //   name: "Pay in - Worldline",
        //   icon: <FaSitemap />,
        // },
      ],
      roles: ["4"],
    },
    {
      path: "payments/pay-out-eko",
      name: "Payouts",
      icon: <FaRegListAlt />,
      subnav: [
        // {
        //   path: "payments/pay-out-eko",
        //   name: "Pay out",
        //   icon: <FaSitemap />,
        // },
        // {
        //   path: "/transactions",
        //   name: "Transactions",
        //   icon: <FaSitemap />,
        // },
        // {
        //     path: "payments/pay-out-ps",
        //     name: "Pay out - PaySprint",
        //     icon: <FaSitemap />,
        // },
      ],
      roles: ["4"],
    },
    {
      path: "",
      name: "Users",
      icon: <FaUserAlt />,
      subnav: [
        {
          path: "users/admin",
          name: "Partners",
          icon: <FaUserCircle />,
        },
        {
          path: "users/distributors",
          name: "Distributor",
          icon: <FaUserSecret />,
        },
        {
          path: "users/retailers",
          name: "Retailers",
          icon: <FaUsers />,
        },
      ],
      roles: ["1"],
    },
    // {
    //   path: "",
    //   name: "Support Ticklets",
    //   icon: <FaRegListAlt />,
    //   subnav: [
    //     {
    //       path: "pendingTickets",
    //       name: "Pending",
    //       icon: <FaSitemap />,
    //     },
    //     {
    //       path: "closedTickets",
    //       name: "Closed",
    //       icon: <FaSitemap />,
    //     },
    //     {
    //       path: "solvedTickets",
    //       name: "Solved",
    //       icon: <FaSitemap />,
    //     },
    //   ],
    //   roles: ["2", "3", "4"],
    // },

    // {
    //   path: "",
    //   name: "Banking",
    //   icon: <FaRegListAlt />,
    //   subnav: [
    //     {
    //       path: "verifyPan",
    //       name: "Verify PAN",
    //       icon: <FaSitemap />,
    //     },
    //     {
    //       path: "verifyAadhar",
    //       name: "Verify AADHAR",
    //       icon: <FaSitemap />,
    //     },
    //     {
    //       path: "verifyBank",
    //       name: "Verify Bank",
    //       icon: <FaSitemap />,
    //     },
    //   ],
    //   roles: ["2", "3", "4"],
    // },

    {
      path: "users/dretailers",
      name: "Retailers",
      icon: <FaUserAlt />,
      subnav: [],
      roles: ["3"],
    },
    {
      path: "/paymentlinks",
      name: "Reports",
      icon: <HiDocumentReport />,
      subnav: [
        {
          path: "/paymentlinks",
          name: "Payment Links",
          icon: <TfiAngleDoubleLeft />,
        },
        {
          path: "/payoutreports",
          name: "Payouts",
          icon: <TfiAngleDoubleRight />,
        },
        {
          path: "/rechargereports",
          name: "Recharges",
          icon: <TbRecharging />,
        },
        {
          path: "/billpaymentreports",
          name: "Bill Payments",
          icon: <BiListCheck />,
        },
      ],
      roles: ["1", "2", "3", "4"],
    },

    {
      path: "/slabs",
      name: "Slabs",
      icon: <IoMdPricetag />,
      subnav: [],
      roles: ["1"],
    },

    // {
    //   path: "/settings",
    //   name: "Settings",
    //   icon: <FaUserAlt />,
    //   subnav: [],
    //   roles: ["2"],
    // },
  ];
  return (
    <>
      {pathName[1] !== "login" && AuthService.isAuthenticated() ? (
        <div
          className="sidenav-layout"
          style={{ fontFamily: "biryani-medium", fontSize: "16" }}
        >
          <div
            style={{ width: isOpen ? "200px" : "50px", transition: "all 0.5s" }}
            className="sidebar"
          >
            <div className="top_section">
              <h1
                style={{
                  display: isOpen ? "block" : "none",
                  fontSize: "1rem",
                  color: "white",
                }}
                className="logo"
              >
                EazyPey
              </h1>
              <div
                style={{ marginLeft: isOpen ? "85px" : "0px" }}
                className="bars"
              >
                <FaBars onClick={toggle} />
              </div>
            </div>
            {menuItem.map(
              (item, menuIndex) =>
                // <SidebarLink item={item} isMenuExpand={isOpen} />
                AuthService.checkUserAccess(item.roles) && (
                  <SidebarLink
                    item={item}
                    isMenuExpand={isOpen}
                    activeMenu={activeMenu}
                    selectedMenu={(menu) => {
                      setActiveMenu(menu);
                    }}
                  />
                )
            )}
          </div>
          <main>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
              <Container>
                <Navbar.Brand href="#home"><img style={{ height: 35 }} src="https://c4admin.eazypey.com/webroot/images/logo/logo3.png" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                  </Nav>
                  <Nav>
                    <Nav.Link><TfiHelpAlt style={{ height: 30, width: 30 }} /></Nav.Link>
                    <NavDropdown title={<HiOutlineUserCircle style={{ height: 35, width: 35 }} />} id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={() => navigate("/profile")}>My Account</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => logout()}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <div
              style={{
                marginLeft: isOpen ? "200px" : "0px",
                marginTop: "10px",
              }}
            >
              {children}
            </div>
          </main>
        </div>
      ) : (
        <main>{children}</main>
      )}
    </>
  );
};

export default Sidebar;


{/* <Nav
              variant="pills"
              className="header-nav"
              // activeKey="/home"
            >
              <Nav.Item>
                <img style={{width:130, height:35, marginTop: 15}} src="https://c4admin.eazypey.com/webroot/images/logo/logo3.png"/>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>Help</Nav.Link>
              </Nav.Item>
              <NavDropdown title="Profile" id="nav-dropdown">
                <NavDropdown.Item
                  eventKey="4.1"
                  onClick={() => navigate("/profile")}
                >
                  My Account
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="4.4" onClick={() => logout()}>
                  <span>Logout</span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav> */}
import React, { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import {
  ZFetchBillRequest,
  ZPayBillRequest,
  ZpayRechargeRequest,
  getBbpsOptionsRequest,
  getBbpsProvidersRequest,
  getZpayOperatorsRequest,
} from "../rechargezpay/actions/onZpayBankingResultAction";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import { updateReportService } from "../../api/reportsAPI";

const ZpBbps = () => {
  const dispatch = useDispatch();
  const [rcForm, setRcForm] = useState({
    amount: "",
    account: "",
    operator: "",
  });
  const [operators, setOPerators] = useState([]);
  const [providers, setProviders] = useState([]);
  const [accountTitle, setAccountTitle] = useState("Account Number");
  const [billInfo, setBillInfo] = useState("");
  const [billInfoError, setBillInfoError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isCorrectBill, setIsCorrectBill] = useState(false);
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    getOperators();
    console.log("mobile rc page");
  }, []);

  const getOperators = async () => {
    setIsStoring(true);
    await dispatch(getBbpsOptionsRequest({}, dispatch)).then((response) => {
      setIsStoring(false);
      if (response) {
        setOPerators(response.result);
        getBbpsProviders(response.result[0].type);
      }
    });
  };

  const getBbpsProviders = async (optionType) => {
    setIsStoring(true);
    await dispatch(
      getBbpsProvidersRequest({ type: optionType }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response) {
        setProviders(response.result);
        setAccountTitle(response.result[0].param);
      }
    });
  };

  const handleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleOptionChanges = (e) => {
    setBillInfo("");
    setBillInfoError("");
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
    getBbpsProviders(e.target.value);
  };

  const handleProviderChanges = (e) => {
    setBillInfo("");
    setBillInfoError("");
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
    let item = providers.find((element) => {
      return element.code === e.target.value;
    });
    console.log(item);
    setAccountTitle(item.param);
  };

  const updateTransaction = (res) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "ZPAY",
      amount: rcForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "RECHARGE",
      page: "POSTPAD",
      pageOption: "POSTPAD",
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {});
  };

  const makeTransaction = (e) => {
    let body = {
      code: rcForm.operator,
      account: rcForm.account,
      amount: rcForm.amount,
    };

    dispatch(ZPayBillRequest(body, dispatch)).then((response) => {
      console.log(response);
      updateTransaction(response);
      if (response) {
        if (response.status === "false") {
          alert("Please enter all fields and try again");
        } else {
          alert(response.message);
        }
      } else {
        alert("Please enter all fields and try again");
      }
    });
  };

  const fetchBill = (e) => {
    setBillInfo("");
    setBillInfoError("");
    setLoading(true);
    let body = {
      code: rcForm.operator,
      account: rcForm.account,
    };

    dispatch(ZFetchBillRequest(body, dispatch)).then((response) => {
      console.log(response);
      if (response) {
        setLoading(false);
        if (response.status === "false") {
          alert("Error in retriving Bill");
        } else {
          if (response.result.status == "error") {
            setBillInfoError(response.result.errortext);
          } else {
            setRcForm({ ...rcForm, amount: response.result.billAmount });
            setBillInfo(
              response.result.name + " / " + response.result.billAmount
            );
          }
        }
      } else {
        setLoading(false);
        alert("Please enter all fields and try again");
      }
    });
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      <div className="container pt-2">
        <div className="row justify-content-around m-5">
          <div>
            <h2 className="pageHeading">BBPS Pay Bills</h2>
          </div>
          <div className="formcontainer" id="transfer">
            <div className="row justify-content-around m-5">
              <div className="col-6">
                <label>Service *</label>
                <select
                  name="operator"
                  className="form-control"
                  onChange={(e) => handleOptionChanges(e)}
                >
                  {operators.map((e, key) => {
                    return (
                      <option key={key} value={e.type}>
                        {e.type}
                      </option>
                    );
                  })}
                </select>

                <label>Provider *</label>
                <select
                  name="operator"
                  className="form-control"
                  onChange={(e) => handleProviderChanges(e)}
                >
                  {providers.map((e, key) => {
                    return (
                      <option key={key} value={e.code}>
                        {e.service}
                      </option>
                    );
                  })}
                </select>

                <label>{accountTitle}</label>
                <input
                  name="account"
                  maxLength="20"
                  className="form-control"
                  value={rcForm.account}
                  onChange={(e) => handleChanges(e)}
                />
                <label>Amount *</label>
                <input
                  name="amount"
                  maxLength="20"
                  type="number"
                  className="form-control"
                  value={rcForm.amount}
                  onChange={(e) => handleChanges(e)}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{ display: "flex", justifyContent: "center" }}
                  type="button"
                  className="btn btn-purple-bg btn-min-width"
                  onClick={() => fetchBill()}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="loader-anim"></div>
                  ) : (
                    "Fetch Bill"
                  )}
                </button>

                <button
                  style={{ display: "flex", justifyContent: "center" }}
                  type="button"
                  className="btn btn-purple-bg"
                  onClick={() => makeTransaction()}
                  disabled={isLoading}
                >
                  {isLoading ? <div className="loader-anim"></div> : "Pay"}
                </button>
              </div>

              <p className="success-text">{billInfo}</p>
              <p className="error-text">{billInfoError}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ZpBbps;

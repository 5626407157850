import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import PayMentStatus from "../../components/payment-status-popup";
import ChangePassword from "../../components/change-password";
import logo from "../../assets/logo.png";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  useEffect(() => {}, []);

  React.useEffect(() => {});

  return (
    <>
      {showChangePasswordForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-4">
              <ChangePassword
                onClose={() => {
                  console.log("Add User closed");
                  setShowChangePasswordForm(false);
                }}
                parentToChild={{id: localStorage.getItem("userId")}}
              />
            </div>
          </div>
        </div>
      )}

      <div class="container">
        <div class="row justify-content-around m-5">
          <h1>My Account</h1>
          <div class="row gutters-sm">
            <div class="col-md-4 mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center">
                    <img src={logo} alt="Admin" width="250" />
                    <div class="mt-3">
                      <h4>John Doe</h4>
                      <p class="text-secondary mb-1">
                        {localStorage.getItem("userName")}
                      </p>
                      <p class="text-muted font-size-sm">
                        {localStorage.getItem("userEmail")} /{" "}
                        {localStorage.getItem("userPhone")}
                      </p>
                      {/* <button class="btn btn-primary">Follow</button> */}
                      <button
                        class="btn btn-outline-primary"
                        onClick={() => setShowChangePasswordForm(true)}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

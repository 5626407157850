import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getBeneficiaryListRequest,
  initiatePaymentRequest,
} from "../recharge/actions/onBankingResultAction";
import { vendorWalletChackUserRequest } from "../VendorWallet/vendorWalletAPIActions";
import AddUser from "../../components/add-user-form";
import AddBeneficiary from "../../components/add-beneficiaryform";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import { FaIdCardAlt, FaSearch, FaUserPlus } from "react-icons/fa";
import { updateReportService } from "../../api/reportsAPI";

const PayOutEko = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {});

  const handleTranferChanges = (e) => {
    console.log("Debug > ", e);
    setEkoForm({ ...ekoForm, [e.target.name]: e.target.value });
  };

  const [ekoForm, setEkoForm] = useState({
    recipient_name: "",
    account: "",
    confirm_account: "",
    ifsc: "",
    amount: "",
    // beneficiary_account_type:""
  });

  const [userSearchForm, setUserSearchForm] = useState({
    username: "",
  });
  const [userData, setUserData] = useState({});
  const [beneficiartList, setBeneficiartList] = useState([]);
  const [showAddBeneficiaryForm, setShowAddBeneficiaryForm] = useState(false);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [isStoring, setIsStoring] = useState(false);

  const makeTransaction = async () => {
    if (ekoForm.recipient_name.length === 0) {
      alert("Enter beneficiary name.");
    } else if (ekoForm.account.length === 0) {
      alert("Enter beneficiary account number.");
    } else if (ekoForm.confirm_account.length === 0) {
      alert("Enter confirm beneficiary account number.");
    } else if (ekoForm.confirm_account !== ekoForm.account) {
      alert(
        "Enter beneficiary account number and confirm beneficiary account number are same"
      );
    } else if (ekoForm.ifsc.length === 0) {
      alert("Enter receiver bank's IFSC code.");
    } else if (ekoForm.amount.length === 0) {
      alert("Enter amount");
    } else {
      console.log("Initiate transfer");
      setIsStoring(true);
      await dispatch(
        initiatePaymentRequest(
          {
            amount: ekoForm.amount,
            name: ekoForm.recipient_name,
            account: ekoForm.account,
            ifsc: ekoForm.ifsc,
          }
          // dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          updateTransaction(response);
          let emptyvals = {
            recipient_name: "",
            account: "",
            confirm_account: "",
            ifsc: "",
            amount: "",
          };

          setEkoForm(emptyvals);
          alert(response.message);
        }
      });
    }
  };

  const updateTransaction = (res) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "EKO",
      amount: ekoForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "PAY_OUT",
      page: "PAY_OUT",
      pageOption: "PAY_OUT",
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {});
  };

  const handleUserSearchForm = (e) => {
    console.log("Debug > ", e);
    setUserSearchForm({ ...userSearchForm, [e.target.name]: e.target.value });
    if (e.target.value == "") {
      setBeneficiartList([]);
      setUserData({});
      clearBeneciafiaryForm();
    }
  };

  const searchUser = async () => {
    if (userSearchForm.username.length === 0) {
      alert("Please enter user name");
    } else {
      setIsStoring(true);
      await dispatch(
        vendorWalletChackUserRequest(
          { username: userSearchForm.username },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response.status === true) {
          setUserData(response.result);
          getBeneficiarydetails(response.result.id);
        } else {
          // alert(response.message);
          setUserData({});
          setBeneficiartList([]);
          setShowAddUserForm(true);
        }
      });
    }
  };

  const getBeneficiarydetails = async (id) => {
    setIsStoring(true);
    await dispatch(
      getBeneficiaryListRequest(
        {
          userId: id,
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.status === true) {
        setBeneficiartList(response.result);
      } else {
        alert(response.message);
      }
    });
  };

  const manageBeneficiaries = (prop) => {
    return (
      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">{prop.account_name}</div>
          {prop.bank_name}
        </div>
        <span
          onClick={() => fillBeneciafiaryForm(prop)}
          class="badge bg-primary rounded-pill"
        >
          Select
        </span>
      </li>
    );
  };

  const fillBeneciafiaryForm = (beneficiaryDetails) => {
    let ekoDetails = {
      recipient_name: beneficiaryDetails.account_name,
      account: beneficiaryDetails.account_number,
      confirm_account: beneficiaryDetails.account_number,
      ifsc: beneficiaryDetails.ifsc_code,
      amount: "",
    };
    setEkoForm(ekoDetails);
  };

  const clearBeneciafiaryForm = () => {
    let ekoDetails = {
      recipient_name: "",
      account: "",
      confirm_account: "",
      ifsc: "",
      amount: "",
    };
    setEkoForm(ekoDetails);
  };

  const addBeneficiary = () => {
    if (userData.id != null) {
      setShowAddBeneficiaryForm(true);
    } else {
      alert("Please search customer");
    }
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {showAddBeneficiaryForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-4">
              <AddBeneficiary
                onClose={() => {
                  console.log("Add User closed");
                  setShowAddBeneficiaryForm(false);
                  getBeneficiarydetails(userData.id);
                }}
                parentToChild={userData}
              />
            </div>
          </div>
        </div>
      )}

      {showAddUserForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-4">
              <AddUser
                onClose={(result) => {
                  if (result != null) {
                    setUserData(result);
                    getBeneficiarydetails(result.id);
                  }
                  setShowAddUserForm(false);
                }}
                parentToChild={{
                  roleId: "5",
                  userId: localStorage.getItem("userId"),
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="container pt-2">
          <div className="row mt-0 justify-content-around m-5">
            <div>
              <h2 className="pageHeading">Pay Out</h2>
            </div>
            <div className="mt-0" id="transfer" style={{ marginTop: 0 }}>
              <div className="row justify-content-around">
                <div className="col-6">
                  <div class="card">
                    <div class="card-body">
                      <form class="row g-3">
                        <div class="col-auto">
                          <label for="inputPassword2" class="visually-hidden">
                            Password
                          </label>
                          <input
                            type="text"
                            name="username"
                            placeholder="Search User (Mobile Number)"
                            className="form-control"
                            value={userSearchForm.username}
                            onChange={(e) => handleUserSearchForm(e)}
                          />
                        </div>
                        <div class="col-auto">
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => searchUser()}
                          >
                            <span>
                              <FaSearch style={{ width: 15, height: 15 }} />{" "}
                            </span>
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => addBeneficiary()}
                          >
                            Add Beneficiary{" "}
                            <span>
                              <FaUserPlus style={{ width: 15, height: 15 }} />
                            </span>
                          </button>
                        </div>
                      </form>
                      {userData != null && (
                        <h6 class="table">{userData.name}</h6>
                      )}
                      <br />
                      <ol class="list-group list-group-numbered">
                        {beneficiartList != null ? (
                          beneficiartList.map((beneficiary) =>
                            manageBeneficiaries(beneficiary)
                          )
                        ) : (
                          <></>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div class="card">
                    <div class="card-body">
                      <label style={{ fontSize: "16px" }}>
                        Sender Name: {userData.name}
                      </label>{" "}
                      <br />
                      <br />
                      <label style={{ fontSize: "16px" }}>
                        Phone: {userData.phone}
                      </label>{" "}
                      <br />
                      <br />
                      <label style={{ fontSize: "16px" }}>
                        Mail: {userData.email}
                      </label>{" "}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-content-around mt-3 p-5 card mt-0">
                <div className="row">
                  <div className="col-6">
                    <label>Beneficiary name</label>
                    <input
                      type="text"
                      name="recipient_name"
                      className="form-control"
                      value={ekoForm.recipient_name}
                      onChange={(e) => handleTranferChanges(e)}
                    />
                  </div>
                  <div className="col-6">
                    <label>Beneficiary account number</label>
                    <input
                      type="password"
                      name="account"
                      className="form-control"
                      value={ekoForm.account}
                      onChange={(e) => handleTranferChanges(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label>Confirm Beneficiary account number</label>
                    <input
                      type="number"
                      name="confirm_account"
                      className="form-control"
                      value={ekoForm.confirm_account}
                      onChange={(e) => handleTranferChanges(e)}
                    />
                  </div>
                  <div className="col-6">
                    <label>Beneficiary IFSC Code</label>
                    <input
                      type="text"
                      name="ifsc"
                      className="form-control"
                      value={ekoForm.ifsc}
                      onChange={(e) => handleTranferChanges(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label>Amount</label>
                    <input
                      type="number"
                      name="amount"
                      className="form-control"
                      value={ekoForm.amount}
                      onChange={(e) => handleTranferChanges(e)}
                    />
                  </div>
                  <div className="col-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: 20,
                      }}
                    >
                      <button
                        style={{ display: "flex", justifyContent: "center" }}
                        type="button"
                        className="btn btn-purple-bg"
                        onClick={() => makeTransaction()}
                      >
                        Transfer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayOutEko;

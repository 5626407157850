import React from "react";
import { RingLoader } from "react-spinners";

const RingLoadingHud = () => {
    return (
        <div
            style={{
                position: "fixed",
                backgroundColor: "rgba(0,0,0,0.5)",
                width: "100%",
                height: "100%",
                zIndex: "999",
                overflow: "scroll",
                top: 0
            }}
        >
            <div className="loaderParentDiv">
                <div className="loaderDivCenter">
                    <RingLoader color="#36d7b7" loading size={200} />
                </div>
            </div>
        </div>
    );
};

export default RingLoadingHud;

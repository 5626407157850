import { vendorWalletChackUserRequestSession, vendorWalletUpdateRequestSession } from "../../api/vendorwallet";

const vendorWalletChackUserRequest = (data) => async (dispatch) => {
    const response = await dispatch(vendorWalletChackUserRequestSession(data));
    if (
      (response &&
        Object.keys(response).length &&
        response?.data?.status === 201) ||
      200
    ) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  }

  const vendorWalletUpdate = (data) => async (dispatch) => {
    const response = await dispatch(vendorWalletUpdateRequestSession(data));
    if (
      (response &&
        Object.keys(response).length &&
        response?.data?.status === 201) ||
      200
    ) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  }
  
  export { vendorWalletChackUserRequest, vendorWalletUpdate };
import {
  getReportsAPIService,
  updateReportService,
} from "../../api/reportsAPI";

const getReportsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getReportsAPIService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const updateReportsRequest = (data) => async (dispatch) => {
  const response = await dispatch(updateReportService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export { getReportsRequest, updateReportsRequest };

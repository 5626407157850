import {
  updateBalanceAPI,
  v_balanceAPI,
  v_balance_total,
  updatePayInLog,
  v_balanceAPI_ByEntry,
  walletBalance,
} from "../../api/vBalanceApi";

const getV_Balance = (data) => async (dispatch) => {
  const response = await dispatch(v_balanceAPI(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getV_Balance_ByEntryRequest = (data) => async (dispatch) => {
  const response = await dispatch(v_balanceAPI_ByEntry(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getV_Balance_Total = (data) => async (dispatch) => {
  const response = await dispatch(v_balance_total());
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const updateBalanceAction = (data) => async (dispatch) => {
  const response = await dispatch(updateBalanceAPI(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const updatePayInLogAction = (data) => async (dispatch) => {
  const response = await dispatch(updatePayInLog(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getWalletBalance = (data) => async (dispatch) => {
  const response = await dispatch(walletBalance(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export {
  getV_Balance,
  getV_Balance_Total,
  updateBalanceAction,
  updatePayInLogAction,
  getV_Balance_ByEntryRequest,
  getWalletBalance,
};

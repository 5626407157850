import { OTPMESSAGE, OTPSTATUS } from "../types/types";

const initialState = {
  message: "",
  otpStatus: false,
};
export const otpReducer = (state = initialState, action) => {
  switch (action.type) {
    case OTPMESSAGE:
      return {
        message: action.payload,
      };
    case OTPSTATUS:
      return {
        otpStatus: action.payload,
      };
    default:
      return state;
  }
};

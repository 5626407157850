import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { getZpayOperatorsRequest } from "./actions/onZpayBankingResultAction";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import { updateReportService } from "../../api/reportsAPI";

const ZMobilePostpaid = () => {
  const dispatch = useDispatch();
  const [rcForm, setRcForm] = useState({
    amount: "",
    mobile: "",
    operator: "",
  });
  const [operators, setOPerators] = useState([]);
  const [isStoring, setIsStoring] = useState(false);

  const handleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const makeTransaction = async () => {
    console.log(rcForm.mobile, rcForm.operator, rcForm.amount);
  };

  const updateTransaction = (res) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "ZPAY",
      amount: rcForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "RECHARGE",
      page: "POSTPAD",
      pageOption: "POSTPAD",
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {});
  };

  useEffect(() => {
    getOperators();
    console.log("mobile rc page");
  }, []);

  const getOperators = async () => {
    setIsStoring(true);
    await dispatch(
      getZpayOperatorsRequest({ type: "POSTPAID" }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response) {
        console.log(response.data);
        setOPerators(response.result);
      }
    });
  };

  const handleListClick = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setRcForm({ ...rcForm, amount: e.amount });
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      <div>
        <div className="container">
          <div className="row align-items-start">
            <div
              className="col-4 mt-0 justify-content-around formcontainer"
              id="transfer"
            >
              <div>
                <label>Operator</label>
                <select
                  name="operator"
                  className="form-control"
                  onChange={(e) => handleChanges(e)}
                >
                  {operators.map((e, key) => {
                    return (
                      <option key={key} value={e.code}>
                        {e.service}
                      </option>
                    );
                  })}
                </select>
                <label>Mobile Number</label>
                <input
                  type="number"
                  name="mobile"
                  maxLength="20"
                  className="form-control"
                  value={rcForm.mobile}
                  onChange={(e) => handleChanges(e)}
                />
                <label>Amount</label>
                <input
                  name="amount"
                  maxLength="20"
                  type="number"
                  className="form-control"
                  value={rcForm.amount}
                  onChange={(e) => handleChanges(e)}
                />
              </div>
              {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
              <button
                style={{ display: "flex", justifyContent: "center" }}
                type="button"
                className="btn btn-purple-bg"
                onClick={() => makeTransaction()}
              >
                Pay
              </button>
              {/* </div> */}
            </div>

            <div
              className="col-7 formcontainer"
              style={{ marginLeft: 20, marginTop: 0 }}
            >
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Plans">
                  {/* <div>
                  {rcPlans.map(plan => (
                    <li onClick={() => {handleListClick(plan)}}>
                      {plan.planName} {"<>"} {plan.amount}
                    </li>
                  ))}
                </div> */}
                  <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {operators.map((plan) => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td>
                            <button
                              className="roundButton"
                              onClick={() => handleListClick(plan)}
                            >
                              Rs {plan.amount}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZMobilePostpaid;

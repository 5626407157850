import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Protected from "./components/protected-route";
import Sidebar from "./components/sidebar";
import Dashboard from "./pages/dashboard/dashboard";
import Settings from "./pages/settings/settings";
import Login from "./pages/login/login";
import PayIn from "./pages/payIn/payIn";
import Vreport from "./pages/vbalance/virtual_balance";
import AdminUsers from "./pages/users/admin/admin";
import Retailers from "./pages/users/retailers/retailers";
import Distributors from "./pages/users/distributors/distributors";
import PayOutEko from "./pages/payoOut/eko";
import PayOutPaySprint from "./pages/payoOut/paysprint";
import Categories from "./pages/categories/categories";
import Providers from "./pages/providers/providers";
import SolvedTickets from "./pages/supporttickets/solved/solved";
import PendingTickets from "./pages/supporttickets/pending/pending";
import ClosedTickets from "./pages/supporttickets/closed/closed";
import VerifyAadhar from "./pages/banking/verifyaadhar/verifyaadhar";
import VerifyBank from "./pages/banking/verifybank/verifybank";
import VerifyPan from "./pages/banking/verifypan/verifypan";
import Reports from "./pages/reports/reports";
import ZRecharge from "./pages/rechargezpay/zrecharge";
import ZpBbps from "./pages/bbps/zpaybills";
import Profile from "./pages/profile/profile";
import VendorWalletTransactions from "./pages/VendorWallet/wallettransactions/wallettransactions";
import VendorAddWallet from "./pages/VendorWallet/addwallet/addwallet";
import Transactions from "./pages/reports/transactions";
import DRetailers from "./pages/users/dretailers";
import VWalletTransactions from "./pages/VendorWallet/vendorWallettransactions";
import DstrAddWallet from "./pages/VendorWallet/dAddwallet";
import NewUser from "./pages/newuser/newuser";
import Slabs from "./pages/slabs/slabs";
import ImagesUpload from "./pages/imagesupload/imagesupload";
import PaymentLinks from "./pages/reports/paymentlinks/paymentlinks";
import BillPaymentReports from "./pages/reports/billpaymentsreports/billpaymentreports";
import PayoutReports from "./pages/reports/reports_payouts/payoutreports";
import RechargesReports from "./pages/reports/rechargereports/rechargereports";

function App() {
  let addressUrl = new URL(window.location.href);
  let pathName = addressUrl.pathname.split("/");

  return (
    <BrowserRouter>
      {/* {console.log(localStorage.getItem("authToken"))} */}
      {localStorage.getItem("authToken") ? (
        <Sidebar>
          <Routes>
            <Route path="*" element={<Navigate to="/login" />} />
            <Route
              path="/dashboard"
              element={
                <Protected>
                  <Dashboard />
                </Protected>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/bbpszp" element={<ZpBbps />} />
            <Route path="/payments/pay-in" element={<PayIn />} />
            <Route path="/payments/pay-out-eko" element={<PayOutEko />} />
            <Route path="/payments/pay-out-ps" element={<PayOutPaySprint />} />
            {/* <Route path="/recharge/mobile" element={<ZRecharge />} /> */}
            {/* <Route path="/recharge/dth" element={<DthRecharge />} /> */}
            <Route path="/settings" element={<Settings />} />
            <Route path="/vbalance" element={<Vreport />} />
            <Route path="/users/admin" element={<AdminUsers />} />
            <Route path="/users/distributors" element={<Distributors />} />
            <Route path="/users/retailers" element={<Retailers />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/providers" element={<Providers />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/solvedTickets" element={<SolvedTickets />} />
            <Route path="/pendingTickets" element={<PendingTickets />} />
            <Route path="/closedTickets" element={<ClosedTickets />} />
            <Route path="/verifyAadhar" element={<VerifyAadhar />} />
            <Route path="/verifyBank" element={<VerifyBank />} />
            <Route path="/verifyPan" element={<VerifyPan />} />
            <Route path="/recharge" element={<ZRecharge />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route
              path="users/dretailers"
              element={
                <Protected>
                  <DRetailers />
                </Protected>
              }
            />
            <Route path="vwtransactions" element={<VWalletTransactions />} />
            <Route path="drwallet" element={<DstrAddWallet />} />
            <Route
              path="/vendorwallettransactions"
              element={<VendorWalletTransactions />}
            />
            <Route path="/vendoraddwallet" element={<VendorAddWallet />} />
            <Route path="/adduser" element={<NewUser />} />
            <Route path="/slabs" element={<Slabs />} />
            <Route path="/imagesUpload" element={<ImagesUpload />} />
            <Route path="/paymentlinks" element={<PaymentLinks />} />
            <Route
              path="/billpaymentreports"
              element={<BillPaymentReports />}
            />
            <Route path="/payoutreports" element={<PayoutReports />} />
            <Route path="/rechargereports" element={<RechargesReports />} />
          </Routes>
        </Sidebar>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;

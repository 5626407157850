import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddSlabRequest } from "../store/actions/onUserDataResultAction";
import { Toast } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHudPopUp from "./loadinghudpopup";

const AddSlab = (props) => {
  const dispatch = useDispatch();
  const [addUserForm, setAddUserForm] = useState({
    name: "",
    owner: "",
    partner: "",
    distributor: "",
    retailer: "",
  });

  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [isStoring, setIsStoring] = useState(false);

  const handleTranferChanges = (e) => {
    console.log("Debug > ", e);
    setAddUserForm({ ...addUserForm, [e.target.name]: e.target.value });
  };

  const addNewBeneficiary = async () => {
    if (addUserForm.name.length === 0) {
      setShowMessage("Enter name.");
      setShow(true);
    } else if (addUserForm.owner.length === 0) {
      setShowMessage("Enter Admin %.");
      setShow(true);
    } else if (addUserForm.partner.length === 0) {
      setShowMessage("Enter Partner %.");
      setShow(true);
    } else if (addUserForm.distributor.length === 0) {
      setShowMessage("Enter distributor %.");
      setShow(true);
    } else if (addUserForm.retailer.length === 0) {
      setShowMessage("Enter retailer %.");
      setShow(true);
    } else if (
      parseInt(addUserForm.retailer) +
      parseInt(addUserForm.distributor) +
      parseInt(addUserForm.partner) +
      parseInt(addUserForm.owner) >
      100
    ) {
      setShowMessage("Total Percent exceeded than 100.");
      setShow(true);
    } else if (
      parseInt(addUserForm.retailer) +
      parseInt(addUserForm.distributor) +
      parseInt(addUserForm.partner) +
      parseInt(addUserForm.owner) <
      100
    ) {
      setShowMessage("Total Percent less than 100.");
      setShow(true);
    } else {
      console.log("Initiate transfer");
      setIsStoring(true);
      await dispatch(
        AddSlabRequest(
          {
            name: addUserForm.name,
            owner: addUserForm.owner,
            partner: addUserForm.partner,
            distributor: addUserForm.distributor,
            retailer: addUserForm.retailer,
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          let emptyvals = {
            name: "",
            owner: "",
            partner: "",
            distributor: "",
            retailer: "",
          };
          setAddUserForm(emptyvals);

          setShowMessage(response.message);
          setShow(true);
          props.onClose();
        }
      });
    }
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHudPopUp />
      )}
      <div>
        <div className="container">
          <div className="row mt-2">
            <div className="col-md-6 card-1 p-5">
              <h3 className="text-center table">Add Slab</h3>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Slab Title</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={addUserForm.name}
                    onChange={(e) => handleTranferChanges(e)}
                  />
                  <label>Admin %</label>
                  <input
                    type="number"
                    name="owner"
                    className="form-control"
                    value={addUserForm.owner}
                    onChange={(e) => handleTranferChanges(e)}
                  />
                  <label>Partner %</label>
                  <input
                    type="number"
                    name="partner"
                    className="form-control"
                    value={addUserForm.partner}
                    onChange={(e) => handleTranferChanges(e)}
                  />
                  <label>Distributor %</label>
                  <input
                    type="number"
                    name="distributor"
                    className="form-control"
                    value={addUserForm.confirm_account}
                    onChange={(e) => handleTranferChanges(e)}
                  />
                  <label>Retailer %</label>
                  <input
                    type="number"
                    name="retailer"
                    className="form-control"
                    value={addUserForm.retailer}
                    onChange={(e) => handleTranferChanges(e)}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{ display: "flex", justifyContent: "center" }}
                      type="button"
                      className="btn btn-purple-bg"
                      onClick={() => addNewBeneficiary()}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-white-bg"
                      onClick={() => {
                        if (props.onClose) props.onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 60,
              right: 200,
            }}
          >
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              <Toast.Header className="table" closeButton={false}>
                <strong className="mr-auto">info</strong>
              </Toast.Header>
              <Toast.Body className="table">{showMessage}</Toast.Body>
            </Toast>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSlab;

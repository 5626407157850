import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ZPayRCPlansRequest,
  ZpayRechargeRequest,
  getZpayOperatorsRequest,
} from "./actions/onZpayBankingResultAction";
import { Tab, Tabs } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import { updateReportService } from "../../api/reportsAPI";

const ZDthRecharge = () => {
  const dispatch = useDispatch();
  const [showDthPlanForm, setshowDthPlanForm] = useState(false);
  const [operators, setOPerators] = useState([]);
  const [rcForm, setRcForm] = useState({
    amount: "",
    mobile: "",
    operator: "",
  });
  const [rcPlans, serRcPlans] = useState([]);
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    getOperators();
    console.log("mobile rc page");
  }, []);

  const getOperators = async () => {
    setIsStoring(true);
    await dispatch(getZpayOperatorsRequest({ type: "DTH" }, dispatch)).then(
      (response) => {
        setIsStoring(false);
        if (response) {
          console.log(response.data);
          setOPerators(response.result);
          getPlans(response.result[0].code);
        }
      }
    );
  };

  const updateTransaction = (res) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "ZPAY",
      amount: rcForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "RECHARGE",
      page: "DTH",
      pageOption: "PREPAID",
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {});
  };

  const handleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleCircleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });

    getPlans(e.target.value);
  };

  const getPlans = async (code) => {
    setIsStoring(true);
    await dispatch(
      ZPayRCPlansRequest({ code: code, circleCode: "" }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response.result != null) {
        serRcPlans(response.result.plans);
      } else {
        serRcPlans([]);
      }
    });
  };

  const makeTransaction = (e) => {
    let body = {
      amount: rcForm.amount,
      biller_code: rcForm.operator,
      number: rcForm.mobile,
    };

    dispatch(ZpayRechargeRequest(body, dispatch)).then((response) => {
      console.log(response);
      if (response) {
        updateTransaction(response);
        if (response.status === "false") {
          alert("Please enter all fields and try again");
        } else {
          let emptyvals = {
            amount: "",
            mobile: "",
            operator: "",
          };
          setRcForm(emptyvals);
          alert(response.message);
        }
      } else {
        alert("Please enter all fields and try again");
      }
    });
  };

  const handleListClick = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setRcForm({ ...rcForm, amount: e.amount });
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      <div>
        <div className="container">
          <div className="row align-items-start">
            <div
              className="col-4 mt-0 justify-content-around formcontainer"
              id="transfer"
            >
              <label>Provider</label>
              <select
                name="operator"
                className="form-control"
                onChange={(e) => handleCircleChanges(e)}
              >
                {operators.map((e, key) => {
                  return (
                    <option key={key} value={e.code}>
                      {e.service}
                    </option>
                  );
                })}
              </select>
              <label>DTH Box Number</label>
              <input
                name="mobile"
                type="number"
                className="form-control"
                value={rcForm.mobile}
                onChange={(e) => handleChanges(e)}
              />
              <label>Amount</label>
              <input
                name="amount"
                type="number"
                className="form-control"
                value={rcForm.amount}
                onChange={(e) => handleChanges(e)}
              />
              {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
              <button
                style={{ display: "flex", justifyContent: "center" }}
                type="button"
                className="btn btn-purple-bg"
                onClick={() => makeTransaction()}
              >
                Pay
              </button>
              {/* </div> */}
            </div>
            <div
              className="col-7 formcontainer"
              style={{ marginLeft: 20, marginTop: 0 }}
            >
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Plans">
                  {/* <div>
                  {rcPlans.map(plan => (
                    <li onClick={() => {handleListClick(plan)}}>
                      {plan.planName} {"<>"} {plan.amount}
                    </li>
                  ))}
                </div> */}
                  <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rcPlans.map((plan) => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td>
                            <button
                              className="roundButton"
                              onClick={() => handleListClick(plan)}
                            >
                              Rs {plan.amount}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ZDthRecharge;

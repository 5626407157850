import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const selectedUserList = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.USER_LIST}?role=${data.role}&limit=${data.limit}&offset=${data.offset}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const retailersList = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.USER_LIST}?role=${data.role}&limit=${data.limit}&offset=${data.offset}&superUserId=${data.superUserId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const updatePayoutRequest = () => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.UPDATE_PAYOUT}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};

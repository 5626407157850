import React, { useRef, useState } from "react";
import SegmentedControl from "../../components/segmentedcontrol/segmentedcontroller";
import Transactions from "./transactions";

const Reports = () => {
  return (
    <div className="container pt-2">
      <div className="row justify-content-around m-5">
        <div>
          <h2 className="pageHeading">Reports</h2>
        </div>
      </div>
    </div>
  );
};

export default Reports;

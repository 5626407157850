import {
  dataUserListApi,
  retailersList,
  selectedUserList,
  updatePayoutRequest,
} from "../../api/commonApi";

const getSelectedUsersList = (data) => async (dispatch) => {
  const response = await dispatch(selectedUserList(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getRetailersListRequest = (data) => async (dispatch) => {
  const response = await dispatch(retailersList(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const updatePayOutHandle = () => async (dispatch) => {
  const response = await dispatch(updatePayoutRequest());
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response);
  } else {
    return Promise.resolve(null);
  }
};

export { getSelectedUsersList, updatePayOutHandle, getRetailersListRequest };

import {
  fetchBill,
  getBbpsOptions,
  getBbpsProviders,
  getCircleCodes,
  getZRC_plans,
  getZpayOperators,
  payBill,
  payRecharge,
} from "../../../api/zPayAPI";

const getZpayOperatorsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getZpayOperators(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getCircleCodeRequest = (data) => async (dispatch) => {
  const response = await dispatch(getCircleCodes(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const ZpayRechargeRequest = (data) => async (dispatch) => {
  const response = await dispatch(payRecharge(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getBbpsOptionsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getBbpsOptions(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getBbpsProvidersRequest = (data) => async (dispatch) => {
  const response = await dispatch(getBbpsProviders(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const ZFetchBillRequest = (data) => async (dispatch) => {
  const response = await dispatch(fetchBill(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const ZPayBillRequest = (data) => async (dispatch) => {
  const response = await dispatch(payBill(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};


const ZPayRCPlansRequest = (data) => async (dispatch) => {
  const response = await dispatch(getZRC_plans(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export {
  getZpayOperatorsRequest,
  getCircleCodeRequest,
  ZpayRechargeRequest,
  getBbpsOptionsRequest,
  getBbpsProvidersRequest,
  ZFetchBillRequest,
  ZPayBillRequest,
  ZPayRCPlansRequest
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import PayMentStatus from "../../components/payment-status-popup";
import { updateReportService } from "../../api/reportsAPI";

const PayIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transactionLog, setTransactionLog] = useState([]);
  const [balanceTotal, setBalanceTotal] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [payinForm, setPayinForm] = useState({
    name: "",
    amount: "",
    mobile: "",
    email: "",
  });

  useEffect(() => {
    window.addEventListener("errorCallbackUrl", (data) => {
      let res = data.detail;
      if (
        typeof res != "undefined" &&
        typeof res.paymentMethod != "undefined" &&
        typeof res.paymentMethod.paymentTransaction != "undefined" &&
        typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
        res.paymentMethod.paymentTransaction.statusCode == "0300"
      ) {
        // success block
        setShowStatus(true);
        setPaymentStatus("Success");
        let emptyvals = { name: "", amount: "", mobile: "", email: "" };
        setPayinForm(emptyvals);
      } else if (
        typeof res != "undefined" &&
        typeof res.paymentMethod != "undefined" &&
        typeof res.paymentMethod.paymentTransaction != "undefined" &&
        typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
        res.paymentMethod.paymentTransaction.statusCode == "0398"
      ) {
        // initiated block
        setShowStatus(true);
        setPaymentStatus("Failure");
      } else {
        // error block
        setShowStatus(true);
        setPaymentStatus("Failure");
      }
      updateTransaction(res);
    });
  }, []);

  const updateTransaction = (res) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "Worldline",
      amount: res.paymentMethod.paymentTransaction.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "PAY_IN",
      page: "PAY_IN",
      pageOption: "PAY_IN",
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {});
  };

  const makePament = async () => {
    if (payinForm.name.length == 0) {
      alert("Enter Name");
      return;
    } else if (payinForm.amount.length === 0) {
      alert("Enter amount.");
    } else if (payinForm.mobile.length === 0) {
      alert("Enter mobile number.");
    } else if (payinForm.email.length === 0) {
      alert("Enter email id.");
    } else {
      const pnc = window.test(payinForm);
      console.log(pnc);
    }
  };

  React.useEffect(() => {});

  const handleChanges = (e) => {
    console.log("SDebug > ", e.target.value);
    setPayinForm({ ...payinForm, [e.target.name]: e.target.value });
  };

  return (
    <>
      {showStatus && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-4">
              <PayMentStatus
                status={paymentStatus}
                onClose={() => {
                  setShowStatus(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="container pt-2">
          <div className="row justify-content-around m-5">
            <div>
              <h2 className="pageHeading">PayIn</h2>
            </div>
            <div className="formcontainer" id="transfer">
              <div className="row justify-content-around m-5">
                <div className="col-6">
                  <label>Payee name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={payinForm.name}
                    onChange={(e) => handleChanges(e)}
                  />
                  <label>Amount</label>
                  <input
                    type="number"
                    name="amount"
                    className="form-control"
                    value={payinForm.amount}
                    onChange={(e) => handleChanges(e)}
                  />
                </div>
                <div className="col-6">
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    name="mobile"
                    className="form-mobile"
                    value={payinForm.ifsc}
                    onChange={(e) => handleChanges(e)}
                  />
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={payinForm.email}
                    onChange={(e) => handleChanges(e)}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: 200,
                    }}
                    type="button"
                    className="btn btn-purple-bg"
                    onClick={() => makePament()}
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayIn;

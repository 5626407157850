import React, { useEffect, useRef, useState } from "react";
import {
  vendorWalletChackUserRequest,
  vendorWalletUpdate,
} from "../vendorWalletAPIActions";
import { useDispatch } from "react-redux";
import { CircleLoader } from "react-spinners";
import { Toast } from "react-bootstrap";
import { getV_Balance_ByEntryRequest } from "../../../store/actions/onvBalanceDataResultAction";
import AlertView from "../../../components/alert-view";
import RingLoadingHud from "../../../components/loadinghud";

const VendorAddWallet = () => {
  const dispatch = useDispatch();
  const [addwalletForm, setAddwalletFormForm] = useState({
    username: "",
  });
  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [addAmountWalletForm, setAddAmountWalletForm] = useState({
    walletAmount: "",
    comments: "",
  });
  const [virtualBalance, setVirtualBalance] = useState([]);
  const handleUserChanges = (e) => {
    setAddwalletFormForm({ ...addwalletForm, username: e.target.value });
  };
  const [isStoring, setIsStoring] = useState(false);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const checkUserDetails = async () => {
    if (addwalletForm.username.length === 0) {
      // setShowMessage("please enter Phone number");
      // setShow(true);
      showAlert("Please enter Phone number");
    } else {
      setIsStoring(true);
      await dispatch(
        vendorWalletChackUserRequest(
          { username: addwalletForm.username },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response.status === true) {
          setUserData(response.result);
          getWalletTransactions(5, 0, response.result.id);
        } else {
          setUserData({});
          setShowMessage("No user exist with given mobile number.");
          setShow(true);
        }
      });
    }
  };

  const handleAddWalletChanges = (e) => {
    setAddAmountWalletForm({
      ...addAmountWalletForm,
      [e.target.name]: e.target.value,
    });
  };

  const getWalletTransactions = async (limit, offset, vId) => {
    setIsStoring(true);
    await dispatch(
      getV_Balance_ByEntryRequest(
        {
          limit: limit,
          offset: offset,
          type: "Debit",
          entry: "WALLET",
          vId: vId,
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        setVirtualBalance(response.result);
      }
    });
  };

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  const addFundsToWallet = async () => {
    if (addAmountWalletForm.walletAmount.length === 0) {
      setShowMessage("please enter amount");
      setShow(true);
      // alert("please enter amount");
    } else if (addAmountWalletForm.comments.length === 0) {
      setShowMessage("please enter comments");
      setShow(true);
      // alert("please enter comments");
    } else {
      setIsStoring(true);
      await dispatch(
        vendorWalletUpdate(
          {
            adminId: localStorage.getItem("userId"),
            vendorId: userData.id,
            walletAmount: addAmountWalletForm.walletAmount,
            comments: addAmountWalletForm.comments,
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          setShowMessage("User wallet updated.");
          setShow(true);
          getWalletTransactions(5, 0, userData.id);
          // alert(response.message);
        }
      });
    }
  };

  const showUserDetails = () => {
    return (
      <div className="col">
        {/* <div className="row">
                <h6 className="table">User Name</h6>
                
            </div>
            <br />
            <div className="row">
                <h6 className="table">User Status</h6>
                
            </div> */}
        <h5 className="table" style={{ margin: "25px" }}>
          {userData.name} / {userData.status}
        </h5>
      </div>
    );
  };

  const showAlert = (message) => {
    console.log(message);
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      {
        isAlertView && (
          <AlertView
          message={alertMessage}
          onClose = {()=> {
            setIsAlertView(false);
          }}
        />
        )
      }
      <div className="container pt-2">
        <div className="row justify-content-around m-5">
          <div>
            <h2 className="pageHeading">Funds to wallet</h2>
          </div>
          <div className="formcontainer" id="transfer">
            <div className="row justify-content-left m-3">
              <div className="row justify-content-left">
                <div className="col-3">
                  <label>Check User</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={addwalletForm.username}
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div>
                <div className="col-2">
                  <button
                    style={{ marginTop: "25px" }}
                    type="button"
                    className="btn btn-purple-bg"
                    onClick={() => checkUserDetails()}
                  >
                    Check User
                  </button>
                </div>
                {userData.status != null ? showUserDetails() : <div></div>}
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                {userData.status != null ? (
                  <label>Recent Transactions</label>
                ) : (
                  <div></div>
                )}
                {virtualBalance && virtualBalance.length > 0 && (
                  <div className="col-md-12 card-1">
                    <table className="table table-hover align-middle">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Added By</th>
                          <th scope="col">Vendor</th>
                          <th scope="col">Comments</th>
                          <th scope="col">Type</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {virtualBalance.map((item, i) => {
                          return (
                            <tr key={i}>
                              {/* <th scope="row">{od.id}</th> */}
                              <th className="align-middle">
                                <p>{item.id}</p>
                              </th>
                              <td className="align-middle">
                                <p>
                                  {item.amount}
                                  {/* {getDateFormat(od.created_at)} */}
                                </p>
                              </td>
                              <td className="align-middle">
                                <p>
                                  {item.adminName}
                                  {/* {getDateFormat(od.created_at)} */}
                                </p>
                              </td>
                              <td className="align-middle">
                                <p>
                                  {item.vendorName}
                                  {/* {getDateFormat(od.created_at)} */}
                                </p>
                              </td>
                              <td className="align-middle">
                                <p>
                                  {item.comments}
                                  {/* {getDateFormat(od.created_at)} */}
                                </p>
                              </td>
                              <td className="align-middle">
                                <p>
                                  {item.type}
                                  {/* {getDateFormat(od.created_at)} */}
                                </p>
                              </td>
                              <td className="align-middle">
                                <p>
                                  {item.status}
                                  {/* {getDateFormat(od.created_at)} */}
                                </p>
                              </td>
                              <td className="align-middle">
                                <p class="warning-badge">
                                  {getDateFormat(item.created_at)}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div class="col-md-4">
                {userData.status == "ACTIVE" && (
                  <div className="formcontainer" id="transfer">
                    <div className="col-auto">
                      <div className="row justify-content-around">
                        <h6 className="table">
                          <b>Recharge Wallet</b>
                        </h6>
                        <div className="col-8">
                          <label>Wallet Amount</label>
                          <input
                            type="number"
                            name="walletAmount"
                            className="form-control"
                            value={addwalletForm.walletAmount}
                            onChange={(e) => handleAddWalletChanges(e)}
                          />
                          <label>Comments</label>
                          <input
                            type="text"
                            name="comments"
                            className="form-control"
                            value={addwalletForm.comments}
                            onChange={(e) => handleAddWalletChanges(e)}
                          />
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <button
                              style={{ marginTop: "25px" }}
                              type="button"
                              className="btn btn-purple-bg"
                              onClick={() => addFundsToWallet()}
                            >
                              Add Amount
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 60,
            right: 10,
          }}
        >
          <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Header className="table" closeButton={false}>
              <strong className="mr-auto">info</strong>
            </Toast.Header>
            <Toast.Body className="table">{showMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </>
  );
};

export default VendorAddWallet;

import React, { Component, useRef, useState } from 'react';
// import { Grid, Card, Icon, Image, Button } from 'semantic-ui-react'
import "./dashboard.css";
import Card from './card/card';
import Charts from './chart/chart';
import ECharts from './chart/echart';
import InfoCard from './card/infocard';
import PlanCard from './card/plancard';
import CIcon from '@coreui/icons-react';
import { cilArrowBottom, cilArrowTop, cilOptions } from '@coreui/icons';
import { CChartBar, CChartLine } from '@coreui/react-chartjs';
import { getStyle } from '@coreui/utils';
import { CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CRow, CWidgetStatsA } from '@coreui/react';
import '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core'

import { FaCircle } from 'react-icons/fa';
import Avatar1 from '../../assets/dashboard/images/user/avatar-1.jpg';
import Avatar2 from '../../assets/dashboard/images/user/avatar-2.jpg';
import Avatar3 from '../../assets/dashboard/images/user/avatar-3.jpg';
import Avatar4 from '../../assets/dashboard/images/user/avatar-4.jpg';
import Avatar5 from '../../assets/dashboard/images/user/avatar-5.jpg';
import circle from '../../assets/dashboard/circle.svg'

const Dashboard = () => {

    return (

        <div></div>

        /* <div className="container pt-2">
            <div className="formcontainer" id="transfer">
                 <div>
                    <h3 className='table'>Dashboard</h3>
                </div>
                <CRow style={{ fontFamily: 'biryani-regular' }}>
                    <CCol sm={6} lg={3}>
                        <CWidgetStatsA
                            className="mb-4"
                            customTooltips='false'
                            color="primary"
                            value={
                                <>
                                    26K{' '}
                                    <span className="fs-6 fw-normal">
                                        (-12.4% <CIcon icon={cilArrowBottom} height={20} />)
                                    </span>
                                </>
                            }
                            title="Users"
                            chart={
                                <CChartLine
                                    className="mt-3 mx-3"
                                    style={{ height: '70px' }}
                                    data={{
                                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                                        datasets: [
                                            {
                                                label: 'My First dataset',
                                                backgroundColor: 'transparent',
                                                borderColor: 'rgba(255,255,255,.55)',
                                                pointBackgroundColor: getStyle('--cui-primary'),
                                                data: [65, 59, 84, 84, 51, 55, 40],
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                            tooltip: {
                                                enabled: false
                                            }
                                        },
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false,
                                                    drawBorder: false,
                                                },
                                                ticks: {
                                                    display: false,
                                                },
                                            },
                                            y: {
                                                min: 30,
                                                max: 89,
                                                display: false,
                                                grid: {
                                                    display: false,
                                                },
                                                ticks: {
                                                    display: false,
                                                },
                                            },
                                        },
                                        elements: {
                                            line: {
                                                borderWidth: 1,
                                                tension: 0.4,
                                            },
                                            point: {
                                                radius: 4,
                                                hitRadius: 10,
                                                hoverRadius: 4,
                                            },
                                        },
                                    }}
                                />
                            }
                        />
                    </CCol>
                    <CCol sm={6} lg={3}>
                        <CWidgetStatsA
                            className="mb-4"
                            color="info"
                            value={
                                <>
                                    $6.200{' '}
                                    <span className="fs-6 fw-normal">
                                        (40.9% <CIcon icon={cilArrowTop} height={20} />)
                                    </span>
                                </>
                            }
                            title="Income"
                            chart={
                                <CChartLine
                                    className="mt-3 mx-3"
                                    style={{ height: '70px' }}
                                    data={{
                                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                                        datasets: [
                                            {
                                                label: 'My First dataset',
                                                backgroundColor: 'transparent',
                                                borderColor: 'rgba(255,255,255,.55)',
                                                pointBackgroundColor: getStyle('--cui-info'),
                                                data: [1, 18, 9, 17, 34, 22, 11],
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        },
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false,
                                                    drawBorder: false,
                                                },
                                                ticks: {
                                                    display: false,
                                                },
                                            },
                                            y: {
                                                min: -9,
                                                max: 39,
                                                display: false,
                                                grid: {
                                                    display: false,
                                                },
                                                ticks: {
                                                    display: false,
                                                },
                                            },
                                        },
                                        elements: {
                                            line: {
                                                borderWidth: 1,
                                            },
                                            point: {
                                                radius: 4,
                                                hitRadius: 10,
                                                hoverRadius: 4,
                                            },
                                        },
                                    }}
                                />
                            }
                        />
                    </CCol>
                    <CCol sm={6} lg={3}>
                        <CWidgetStatsA
                            className="mb-4"
                            color="warning"
                            value={
                                <>
                                    2.49{' '}
                                    <span className="fs-6 fw-normal">
                                        (84.7% <CIcon icon={cilArrowTop} height={20} />)
                                    </span>
                                </>
                            }
                            title="Conversion Rate"
                            chart={
                                <CChartLine
                                    className="mt-3"
                                    style={{ height: '70px' }}
                                    data={{
                                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                                        datasets: [
                                            {
                                                label: 'My First dataset',
                                                backgroundColor: 'rgba(255,255,255,.2)',
                                                borderColor: 'rgba(255,255,255,.55)',
                                                data: [78, 81, 80, 45, 34, 12, 40],
                                                fill: true,
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        },
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                display: false,
                                            },
                                            y: {
                                                display: false,
                                            },
                                        },
                                        elements: {
                                            line: {
                                                borderWidth: 2,
                                                tension: 0.4,
                                            },
                                            point: {
                                                radius: 0,
                                                hitRadius: 10,
                                                hoverRadius: 4,
                                            },
                                        },
                                    }}
                                />
                            }
                        />
                    </CCol>
                    <CCol sm={6} lg={3}>
                        <CWidgetStatsA
                            className="mb-4"
                            color="danger"
                            value={
                                <>
                                    44K{' '}
                                    <span className="fs-6 fw-normal">
                                        (-23.6% <CIcon icon={cilArrowBottom} height={20} />)
                                    </span>
                                </>
                            }
                            title="Sessions"
                            chart={
                                <CChartBar
                                    className="mt-3 mx-3"
                                    style={{ height: '70px' }}
                                    data={{
                                        labels: [
                                            'January',
                                            'February',
                                            'March',
                                            'April',
                                            'May',
                                            'June',
                                            'July',
                                            'August',
                                            'September',
                                            'October',
                                            'November',
                                            'December',
                                            'January',
                                            'February',
                                            'March',
                                            'April',
                                        ],
                                        datasets: [
                                            {
                                                label: 'My First dataset',
                                                backgroundColor: 'rgba(255,255,255,.2)',
                                                borderColor: 'rgba(255,255,255,.55)',
                                                data: [78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34, 84, 67, 82],
                                                barPercentage: 0.6,
                                            },
                                        ],
                                    }}
                                    options={{
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        },
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false,
                                                    drawTicks: false,
                                                },
                                                ticks: {
                                                    display: false,
                                                },
                                            },
                                            y: {
                                                grid: {
                                                    display: false,
                                                    drawBorder: false,
                                                    drawTicks: false,
                                                },
                                                ticks: {
                                                    display: false,
                                                },
                                            },
                                        },
                                    }}
                                />
                            }
                        />
                    </CCol>
                </CRow>
                <div className='row'>
                    <div class="col-md-6 col-xl-4">
                        <div class="ncard daily-sales">
                            <div class="ncard-block">
                                <h6 class="mb-4">Daily Sales</h6>
                                <div class="row d-flex align-items-center">
                                    <div class="col-9">
                                        <h3 class="f-w-300 d-flex align-items-center m-b-0"><i class="feather icon-arrow-up text-c-green f-30 m-r-10"></i>$ 249.95</h3>
                                    </div>

                                    <div class="col-3 text-right">
                                        <p class="m-b-0">67%</p>
                                    </div>
                                </div>
                                <div class="progress m-t-30" style={{ height: "7px" }}>
                                    <div class="progress-bar progress-c-theme" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="ncard Monthly-sales">
                            <div class="ncard-block">
                                <h6 class="mb-4">Monthly Sales</h6>
                                <div class="row d-flex align-items-center">
                                    <div class="col-9">
                                        <h3 class="f-w-300 d-flex align-items-center  m-b-0"><i class="feather icon-arrow-down text-c-red f-30 m-r-10"></i>$ 2.942.32</h3>
                                    </div>
                                    <div class="col-3 text-right">
                                        <p class="m-b-0">36%</p>
                                    </div>
                                </div>
                                <div class="progress m-t-30" style={{ height: "7px" }}>
                                    <div class="progress-bar progress-c-theme2" role="progressbar" style={{ width: "35%" }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xl-4">
                        <div class="ncard yearly-sales">
                            <div class="ncard-block">
                                <h6 class="mb-4">Yearly Sales</h6>
                                <div class="row d-flex align-items-center">
                                    <div class="col-9">
                                        <h3 class="f-w-300 d-flex align-items-center  m-b-0"><i class="feather icon-arrow-up text-c-green f-30 m-r-10"></i>$ 8.638.32</h3>
                                    </div>
                                    <div class="col-3 text-right">
                                        <p class="m-b-0">80%</p>
                                    </div>
                                </div>
                                <div class="progress m-t-30" style={{ height: "7px" }}>
                                    <div class="progress-bar progress-c-theme" role="progressbar" style={{ width: "70%" }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-md-6">
                        <div class="ncard Recent-Users">
                            <div class="ncard-header">
                                <h5>Recent Users</h5>
                            </div>
                            <div class="ncard-block px-0 py-3">
                                <div class="table-responsive">
                                    <table class="ntable ntable-hover thead-light">
                                        <tbody>
                                            <tr className="unread">
                                                <td><img class="rounded-circle" style={{ width: "40px" }} src={Avatar1} alt="activity-user" /></td>
                                                <td>
                                                    <h6 class="mb-1">Isabella Christensen</h6>
                                                    <p class="m-0">Lorem Ipsum is simply…</p>
                                                </td>
                                                <td>
                                                    <h6 class="text-muted">
                                                        <FaCircle class="text-c-green f-10 m-r-15" />
                                                        11 MAY 12:56</h6>
                                                </td>
                                                <td><a href="#!" class="label theme-bg2 text-white f-12">Reject</a><a href="#!" class="label theme-bg text-white f-12">Approve</a></td>
                                            </tr>
                                            <tr class="unread">
                                                <td><img class="rounded-circle" style={{ width: "40px" }} src={Avatar2} alt="activity-user" /></td>
                                                <td>
                                                    <h6 class="mb-1">Mathilde Andersen</h6>
                                                    <p class="m-0">Lorem Ipsum is simply text of…</p>
                                                </td>
                                                <td>
                                                    <h6 class="text-muted"><FaCircle class="text-c-red f-10 m-r-15" />11 MAY 10:35</h6>
                                                </td>
                                                <td><a href="#!" class="label theme-bg2 text-white f-12">Reject</a><a href="#!" class="label theme-bg text-white f-12">Approve</a></td>
                                            </tr>
                                            <tr class="unread">
                                                <td><img class="rounded-circle" style={{ width: "40px" }} src={Avatar3} alt="activity-user" /></td>
                                                <td>
                                                    <h6 class="mb-1">Karla Sorensen</h6>
                                                    <p class="m-0">Lorem Ipsum is simply…</p>
                                                </td>
                                                <td>
                                                    <h6 class="text-muted"><FaCircle class="text-c-green f-10 m-r-15" />9 MAY 17:38</h6>
                                                </td>
                                                <td><a href="#!" class="label theme-bg2 text-white f-12">Reject</a><a href="#!" class="label theme-bg text-white f-12">Approve</a></td>
                                            </tr>
                                            <tr class="unread">
                                                <td><img class="rounded-circle" style={{ width: "40px" }} src={Avatar4} alt="activity-user" /></td>
                                                <td>
                                                    <h6 class="mb-1">Ida Jorgensen</h6>
                                                    <p class="m-0">Lorem Ipsum is simply text of…</p>
                                                </td>
                                                <td>
                                                    <h6 class="text-muted f-w-300"><FaCircle class="text-c-red f-10 m-r-15" />19 MAY 12:56</h6>
                                                </td>
                                                <td><a href="#!" class="label theme-bg2 text-white f-12">Reject</a><a href="#!" class="label theme-bg text-white f-12">Approve</a></td>
                                            </tr>
                                            <tr class="unread">
                                                <td><img class="rounded-circle" style={{ width: "40px" }} src={Avatar5} alt="activity-user" /></td>
                                                <td>
                                                    <h6 class="mb-1">Albert Andersen</h6>
                                                    <p class="m-0">Lorem Ipsum is simply dummy…</p>
                                                </td>
                                                <td>
                                                    <h6 class="text-muted"><FaCircle class="text-c-green f-10 m-r-15" />21 July 12:56</h6>
                                                </td>
                                                <td><a href="#!" class="label theme-bg2 text-white f-12">Reject</a><a href="#!" class="label theme-bg text-white f-12">Approve</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6">
                        <div class="ncard ncard-event">
                            <div class="ncard-block">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col">
                                        <h5 class="m-0">Upcoming Event</h5>
                                    </div>
                                    <div class="col-auto">
                                        <label class="label theme-bg2 text-white f-14 f-w-400 float-right">34%</label>
                                    </div>
                                </div>
                                <h2 class="mt-3 f-w-300">45<sub class="text-muted f-14">Competitors</sub></h2>
                                <h6 class="text-muted mt-4 mb-0">You can participate in event </h6>
                                <i class="fab fa-angellist text-c-purple f-50"></i>
                            </div>
                        </div>
                        <div class="ncard">
                            <div class="ncard-block border-bottom">
                                <div class="row d-flex align-items-center">
                                    <div class="col-auto">
                                        <i class="feather icon-zap f-30 text-c-green"></i>
                                    </div>
                                    <div class="col">
                                        <h3 class="f-w-300">235</h3>
                                        <span class="d-block text-uppercase">TOTAL IDEAS</span>
                                    </div>
                                </div>
                            </div>
                            <div class="ncard-block">
                                <div class="row d-flex align-items-center">
                                    <div class="col-auto">
                                        <i class="feather icon-map-pin f-30 text-c-blue"></i>
                                    </div>
                                    <div class="col">
                                        <h3 class="f-w-300">26</h3>
                                        <span class="d-block text-uppercase">TOTAL LOCATIONS</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 stretch-card grid-margin">
                        <div class="jcard bg-gradient-danger jcard-img-holder">
                            <div class="jcard-body">
                                <img src={circle} class="jcard-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3" style={{ color: 'white' }}>Weekly Sales <i className="feather icon-activity f-24 float-end"></i>
                                </h4>
                                <h2 class="mb-5" style={{ color: 'white' }}>$ 15,0000</h2>
                                <h6 class="jcard-text" style={{ color: 'white' }}>Increased by 60%</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 stretch-card grid-margin">
                        <div class="jcard bg-gradient-info jcard-img-holder">
                            <div class="jcard-body">
                                <img src={circle} class="jcard-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3" style={{ color: 'white' }}>Weekly Orders <i class="feather icon-bookmark f-24 float-end"></i>
                                </h4>
                                <h2 class="mb-5" style={{ color: 'white' }}>45,6334</h2>
                                <h6 class="jcard-text" style={{ color: 'white' }}>Decreased by 10%</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 stretch-card grid-margin">
                        <div class="jcard bg-gradient-success jcard-img-holder text-white">
                            <div class="jcard-body">
                                <img src={circle} class="jcard-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3" style={{ color: 'white' }}>Visitors Online <i class="feather icon-globe f-24 float-end"></i>
                                </h4>
                                <h2 class="mb-5" style={{ color: 'white' }}>95,5741</h2>
                                <h6 class="jcard-text" style={{ color: 'white' }}>Increased by 5%</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="ncard Recent-Users">
                        <div class="ncard-header">
                            <h5>Recent payments</h5>
                        </div>
                        <div class="ncard-block px-0 py-3">

                            <div class="table-responsive">
                                <table class="ntable">
                                    <thead style={{ color: "#000"}}>
                                        <tr>
                                            <th> Assignee </th>
                                            <th> Subject </th>
                                            <th> Status </th>
                                            <th> Last Update </th>
                                            <th> Tracking ID </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='m-0'>
                                            <td>

                                                <FaCircle class="text-c-green f-10 m-r-15" /> David Grey
                                            </td>
                                            <td> Fund is not recieved </td>
                                            <td>
                                                <label class="badge badge-gradient-success">DONE</label>
                                            </td>
                                            <td> Dec 5, 2017 </td>
                                            <td> WD-12345 </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FaCircle class="text-c-yellow f-10 m-r-15" /> Stella Johnson
                                            </td>
                                            <td> High loading time </td>
                                            <td>
                                                <label class="badge badge-gradient-warning">PROGRESS</label>
                                            </td>
                                            <td> Dec 12, 2017 </td>
                                            <td> WD-12346 </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FaCircle class="text-c-blue f-10 m-r-15" /> Marina Michel
                                            </td>
                                            <td> Website down for one week </td>
                                            <td>
                                                <label class="badge badge-gradient-info">ON HOLD</label>
                                            </td>
                                            <td> Dec 16, 2017 </td>
                                            <td> WD-12347 </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FaCircle class="text-c-red f-10 m-r-15" /> John Doe
                                            </td>
                                            <td> Loosing control on server </td>
                                            <td>
                                                <label class="badge badge-gradient-danger">REJECTED</label>
                                            </td>
                                            <td> Dec 3, 2017 </td>
                                            <td> WD-12348 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>*/

    );
};

export default Dashboard;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { getOrderList } from "../../store/actions/onDataResultAction";
import moment from "react-moment";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [couponList, setCouponList] = useState({});
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    // getCoupons(10, 0);
  }, []);

  // const getCoupons = async (limit, offset) => {
  //     await dispatch(getCouponList({ limit: limit, offset: offset }, dispatch)).then((response) => {
  //         console.log('productList', response.result);
  //         if (response.result) {
  //             setCouponList(response.result);
  //         }
  //     });
  // }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const getOrderStatusMessage = (orderstatus) => {
    let message = "";
    if (orderstatus === "0") {
      message = "Pending";
    } else if (orderstatus === "1") {
      message = "Accepted";
    } else if (orderstatus === "2") {
      message = "Assigned";
    } else if (orderstatus === "3") {
      message = "Out for Delivery";
    } else if (orderstatus === "5") {
      message = "Delivered";
    }
    return message;
  };

  const getDateFormat = (date) => {
    moment(date).format("MMMM Do YYYY, h:mm:ss a");
  };

  return (
    <>
      <div className="container pt-2">
        <div className="row justify-content-around m-5">
          <div className="col-12">
            <h2 className="pageHeading">Settings</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Settings
                </li>
              </ol>
            </nav>
          </div>
          {/* <div className="col-6 text-end">
                        <button type="button" className="m-2 btn btn-purple-bg" onClick={() => setShowProductForm(true)}>Add</button>
                        <button type="button" className="btn btn-white-bg">Delete</button>
                    </div> */}
        </div>

        <div className="row m-5">
          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Order Id</th>
                  <th scope="col">Date</th>
                  <th scope="col">Delivery Slot</th>
                  <th scope="col">Total Items</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">status</th>
                  <th scope="col">Payment Mode</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {couponList && couponList.length > 0 && (
                <tbody>
                  {couponList.map((od, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <td className="align-middle">
                          <p>{od.id}</p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {od.created_at}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p class="warning-badge">
                            {od.slot_date}
                            <br />
                            {od.slot_time}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>{od.total_items}</p>
                        </td>
                        <td className="align-middle">
                          <p>
                            <b>{"₹" + od.total_roundoff}</b>
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>{getOrderStatusMessage(od.order_status)}</p>
                        </td>
                        <td className="align-middle">
                          <p>{od.payment_type}</p>
                        </td>
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => {
                              navigate(`/order-detail?orderId=${od.id}`);
                            }}
                          >
                            <AiFillEye />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {
              couponList.length > 10
                ? <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={450}
                  pageRangeDisplayed={5}
                  onChange={(number) => handlePageChange(number)}
                />
                : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;

import React from 'react';

const PayOutPaySprint = () => {
    return (
        <div>
            <div className="container pt-2">
                <div className="row justify-content-around m-5">
                    <div className="col-12">
                        <h2 className="pageHeading">Pay Out PaySprint</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Pay Out PaySprint
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div className="col-12">
                        <div className="formcontainer">
                            <div className="form-container" id="mobile">
                                <div className="col-6">
                                    <div className="field-container">
                                        <label for="name">Mobile Number</label>
                                        <input id="name" maxlength="20" type="text" placeholder="10 DIGIT NUMBER" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                        <div className="formcontainer" id="details">
                            <div className="row">
                                <div className="col-6">
                                    <div className="field-container">
                                        <label for="name">First Name</label>
                                        <input id="name" maxlength="20" type="text" placeholder="Enter First Name" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="field-container">
                                        <label for="name">Last Name</label>
                                        <input id="name" maxlength="20" type="text" placeholder="Enter Last Name" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="field-container">
                                        <label for="name">Pincode</label>
                                        <input id="name" maxlength="20" type="text" placeholder="Enter Pincode" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                        <div className="formcontainer" id="transfer">
                            <div className="row">
                                <div className="col-6">
                                    <div className="field-container">
                                        <label for="name">Action AddBeneficiary</label>
                                        <select id="selector" className="form-control">
                                            <option value="FundTransfer" selected="selected">FUND TRANSFER</option>
                                            <option value="AddBeneficiary">ADD BENEFICIARY</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="field-container">
                                        <label for="name">Account Number</label>
                                        <input id="name" maxlength="50" type="text" placeholder="Enter Account Number" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="field-container">
                                        <label for="name">IFSC code</label>
                                        <input id="name" maxlength="20" type="text" placeholder="Enter IFSC code" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="field-container">
                                                <label for="name">Benificiary Name</label>
                                                <input id="name" maxlength="20" type="text" placeholder="Enter Benificiary Name" />
                                            </div>
                                        </div>
                                        <div className="col-4 mtp-5">
                                            <button className="btn" type="submit">Verify AC</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" type="submit">ADD</button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <div className="col-12">

                <div className="card card-min-height">
                    <div className="card-content">
                        <div className="card-body pd-lr-0  pd-xs-10">
                            <div className="row">
                                <div className="col-2 form-group">
                                    <label>Remitter</label>
                                    <div className="inputText">
                                        <input type="text" className="form-control" placeholder="Remitter" maxlength="10" required="" />
                                    </div>
                                </div>
                                <div className="col-2 form-group">
                                    <label>Order ID</label>
                                    <div className="inputText">
                                        <input type="text" className="form-control" placeholder="Order ID" maxlength="10" required="" />
                                    </div>
                                </div>
                                <div className="col-2 form-group">
                                    <label>Bank Account</label>
                                    <div className="inputText">
                                        <input type="text" className="form-control" placeholder="Bank Account" maxlength="16" required="" />
                                    </div>
                                </div>
                                <div className="col-2 form-group">
                                    <label>Transfer Amount</label>
                                    <div className="inputText">
                                        <input type="text" className="form-control" placeholder="Transfer Amount" maxlength="10" required="" />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <label>Status</label>
                                    <div className="inputText">
                                        <select className="form-control">
                                            <option value="" selected="selected">Select</option>
                                            <option value="Success">Success</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Failure">Failure</option>
                                            <option value="Reversal">Reversal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-2 form-group mtp-5">
                                    <button type="submit" className="btn btn-primary btn-block">Search</button>
                                </div>

                                <div className="col-12 col-12 table-responsive">
                                    <div className="newdemo">
                                        <table className="table table-transaction">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Order Details</th>
                                                    <th>Sender Details</th>
                                                    <th>Account Details</th>
                                                    <th>Amount</th>
                                                    <th>Print</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PayOutPaySprint;
import React, { useEffect, useRef, useState } from "react";
import "./zpaystyles.css";
import {
  ZPayRCPlansRequest,
  ZpayRechargeRequest,
  getCircleCodeRequest,
  getZpayOperatorsRequest,
} from "./actions/onZpayBankingResultAction";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import AlertView from "../../components/alert-view";
import { updateReportService } from "../../api/reportsAPI";

const ZMobileRecharge = () => {
  const dispatch = useDispatch();
  const [rcForm, setRcForm] = useState({
    amount: "",
    mobile: "",
    operator: "",
    circle: "",
  });
  const [operators, setOPerators] = useState([]);
  const [circles, setCircles] = useState([]);
  const [sOffer, setSOffer] = useState([]);
  const [topUp, setTopUp] = useState([]);
  const [rcData, setRcData] = useState([]);
  const [rateCutter, setRateCutter] = useState([]);
  const [regular, setRegular] = useState([]);
  const [others, setOthers] = useState([]);
  const [rcPlans, serRcPlans] = useState([]);
  const [isStoring, setIsStoring] = useState(false);

  const [plans, setPlans] = useState([]);
  const [circleCode, setCircleCode] = useState("");
  const [tabsActiveIndex, setTabsActiveIndex] = useState("home");
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    getOperators();
    console.log("mobile rc page");
  }, []);

  const getOperators = async () => {
    setIsStoring(true);
    await dispatch(getZpayOperatorsRequest({ type: "PREPAID" }, dispatch)).then(
      (response) => {
        setIsStoring(false);
        if (response) {
          setOPerators(response.result);
          getCiecles(response.result[0].code);
        }
      }
    );
  };

  const getCiecles = async (code) => {
    setIsStoring(true);
    await dispatch(getCircleCodeRequest({ type: "PREPAID" }, dispatch)).then(
      (response) => {
        setIsStoring(false);
        if (response) {
          setCircles(response.result);
          setRcForm({ ...rcForm, operator: response.result[0].circle_code });
          // circleCode = response.result[0].circle_code
          setCircleCode(response.result[0].circle_code);
          console.log("circle_code ", response.result[0].circle_code);
          getPlans(code, response.result[0].circle_code);
        }
      }
    );
  };

  const handleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleOperatorChanges = (e) => {
    console.log(
      "handleOperatorChanges ",
      e.target.value,
      "circleCode",
      circleCode
    );
    getPlans(e.target.value, circleCode);
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleCircleChanges = (e) => {
    console.log(
      "handleCircleChanges ",
      rcForm.operator,
      "circleCode",
      circleCode,
      "asdf",
      e.target.value
    );
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
    setCircleCode(e.target.value);
    getPlans(rcForm.operator, e.target.value);
  };

  const getPlans = async (code, circleCode) => {
    setIsStoring(true);
    await dispatch(
      ZPayRCPlansRequest({ code: code, circleCode: circleCode }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response.result != null) {
        console.log("resp not null");
        serRcPlans(response.result.plans);
        setSOffer(rcPlans.filter((plan) => plan.planTab == "Special Offer"));
        setTopUp(rcPlans.filter((plan) => plan.planTab == "Top Up"));
        setRcData(rcPlans.filter((plan) => plan.planTab == "Data"));
        setRateCutter(rcPlans.filter((plan) => plan.planTab == "Rate Cutter"));
        setRegular(rcPlans.filter((plan) => plan.planTab == null));
        setOthers(rcPlans.filter((plan) => plan.planTab == "Others"));
        setSOffer(response.result.plans);
        onChangeTab("home");
      } else {
        console.log("resp null");
        serRcPlans([]);
        setSOffer([]);
        setTopUp([]);
        setRcData([]);
        setRateCutter([]);
        setRegular([]);
        setOthers([]);
      }
    });
  };

  const updateTransaction = (res) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "ZPAY",
      amount: rcForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "RECHARGE",
      page: "PREPAID",
      pageOption: "PREPAID",
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {});
  };

  const makeTransaction = (e) => {
    if (rcForm.amount.length === 0) {
      showAlert("Please enter amount");
    } else if (rcForm.mobile.length === 0) {
      showAlert("Please enter mobile number");
    } else {
      let body = {
        amount: rcForm.amount,
        biller_code: rcForm.operator,
        number: rcForm.mobile,
      };

      dispatch(ZpayRechargeRequest(body, dispatch)).then((response) => {
        console.log(response);
        updateTransaction(response);
        if (response) {
          if (response.status === "false") {
            showAlert("Please enter all fields and try again");
          } else {
            let emptyvals = {
              amount: "",
              mobile: "",
              operator: "",
              circle: "",
            };
            setRcForm(emptyvals);
            showAlert(response.message);
          }
        } else {
          showAlert("Please enter all fields and try again");
        }
      });
    }
  };

  const handleListClick = (e) => {
    console.log("hh");
    console.log(e);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setRcForm({ ...rcForm, amount: e.amount });
  };

  const onChangeTab = (e) => {
    console.log("onChangeTab", e);
    setTabsActiveIndex(e);
    switch (e) {
      case "home":
        return setPlans(
          rcPlans.filter((plan) => plan.planTab == "Special Offer")
        );
      case "tp":
        return setPlans(rcPlans.filter((plan) => plan.planTab == "Top Up"));
      case "data":
        return setPlans(rcPlans.filter((plan) => plan.planTab == "Data"));
      case "rc":
        return setPlans(
          rcPlans.filter((plan) => plan.planTab == "Rate Cutter")
        );
      case "reg":
        return setPlans(rcPlans.filter((plan) => plan.planTab == null));
      case "other":
        return setPlans(rcPlans.filter((plan) => plan.planTab == "Others"));
      default:
        return setPlans(
          rcPlans.filter((plan) => plan.planTab == "Smart Phone")
        );
    }
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {isAlertView && (
        <AlertView
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}
      <div>
        <div className="container">
          <div className="row align-items-start">
            <div className="col-4 mt-0 formcontainer">
              <div>
                <label>Operator *</label>
                <select
                  name="operator"
                  className="form-control"
                  onChange={(e) => handleOperatorChanges(e)}
                >
                  {operators.map((e, key) => {
                    return (
                      <option key={key} value={e.code}>
                        {e.service}
                      </option>
                    );
                  })}
                </select>

                <label>Circle *</label>
                <select
                  name="circle"
                  className="form-control"
                  onChange={(e) => handleCircleChanges(e)}
                >
                  {circles.map((e, key) => {
                    return (
                      <option key={key} value={e.circle_code}>
                        {e.circle_name}
                      </option>
                    );
                  })}
                </select>
                <label>Mobile Number *</label>
                <input
                  type="number"
                  name="mobile"
                  maxLength="20"
                  className="form-control"
                  value={rcForm.mobile}
                  onChange={(e) => handleChanges(e)}
                />
                <label>Amount *</label>
                <input
                  name="amount"
                  maxLength="20"
                  type="number"
                  className="form-control"
                  value={rcForm.amount}
                  onChange={(e) => handleChanges(e)}
                />

                <div className="col-6">
                  <button
                    style={{ justifyContent: "center" }}
                    type="button"
                    className="btn btn-purple-bg"
                    onClick={() => makeTransaction()}
                  >
                    Proceed to Pay
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-7 formcontainer"
              style={{ marginLeft: 20, marginTop: 0 }}
            >
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={tabsActiveIndex}
                onSelect={(e) => {
                  onChangeTab(e);
                }}
              >
                <Tab eventKey="home" title="Special Offer"></Tab>
                <Tab eventKey="tp" title="Top Up">
                  {/* <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Talk Time in INR</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topUp.map(plan => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.talktime}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td><button className="my-button" onClick={() => handleListClick(plan)}>{plan.amount}</button></td>
                        </tr>

                      ))}
                    </tbody>
                  </table> */}
                </Tab>
                <Tab eventKey="data" title="Data">
                  {/* <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Talk Time in INR</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rcData.map(plan => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.talktime}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td><button className="my-button" onClick={() => handleListClick(plan)}>{plan.amount}</button></td>
                        </tr>

                      ))}
                    </tbody>
                  </table> */}
                </Tab>
                <Tab eventKey="rc" title="Rate Cutter">
                  {/* <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Talk Time in INR</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rateCutter.map(plan => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.talktime}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td><button className="my-button" onClick={() => handleListClick(plan)}>{plan.amount}</button></td>
                        </tr>

                      ))}
                    </tbody>
                  </table> */}
                </Tab>
                <Tab eventKey="reg" title="Regular">
                  {/* <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Talk Time in INR</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {regular.map(plan => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.talktime}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td><button className="my-button" onClick={() => handleListClick(plan)}>{plan.amount}</button></td>
                        </tr>

                      ))}
                    </tbody>
                  </table> */}
                </Tab>
                <Tab eventKey="other" title="Others">
                  {/* <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Talk Time in INR</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {others.map(plan => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.talktime}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td><button className="my-button" onClick={() => handleListClick(plan)}>{plan.amount}</button></td>
                        </tr>

                      ))}
                    </tbody>
                  </table> */}
                </Tab>
              </Tabs>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Talk Time in INR</th>
                      <th>Validity</th>
                      <th>Description</th>
                      <th>Price in INR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plans.map((plan) => (
                      <tr key={plan.id}>
                        <td>{plan.planName}</td>
                        <td>{plan.talktime}</td>
                        <td>{plan.validity}</td>
                        <td style={{ maxWidth: 350 }}>
                          {plan.planDescription}
                        </td>
                        <td style={{ minWidth: 100 }}>
                          <button
                            className="roundButton"
                            onClick={() => handleListClick(plan)}
                          >
                            Rs {plan.amount}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ZMobileRecharge;

import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const updatePanAPIService = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.UPDATE_PAN}?pan=${data.pan}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const payOutTransfer = (data) => async () => {
  try {
    let result = await ServiceCalls.external_post(
      `${API_URL.PAYOUT_INITIATE_PAYMENT}`,
      data,
      false
    );
    return result;
  } catch (error) {}
};


export const getPrepaidOperators = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.EKO_GET_MOBILE_PREPAID_OPERATORS}${data.id}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};

export const getDTHOperators = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.EKO_GET_DTH_OPERATORS}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};

export const payBillService = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.EKO_PAY_BILL}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const getBeneficiaryListSession = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.BENEFICIARY_LIST}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const addBeneficiarySession = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.BENEFICIARY_ADD}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBeneficiaryRequest } from "../pages/recharge/actions/onBankingResultAction";
import AlertView from "./alert-view";
import { RingLoader } from "react-spinners";
import RingLoadingHudPopUp from "./loadinghudpopup";

const AddBeneficiary = (props) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(props.parentToChild);
  const [addUserForm, setAddUserForm] = useState({
    recipient_name: "",
    account: "",
    confirm_account: "",
    ifsc: "",
    bank_name: "",
    // beneficiary_account_type:""
  });
  const [isStoring, setIsStoring] = useState(false);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleTranferChanges = (e) => {
    console.log("Debug > ", e);
    setAddUserForm({ ...addUserForm, [e.target.name]: e.target.value });
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };
  const addNewBeneficiary = async () => {
    if (addUserForm.recipient_name.length === 0) {
      showAlert("Enter beneficiary name.");
    } else if (addUserForm.account.length === 0) {
      showAlert("Enter beneficiary account number.");
    } else if (addUserForm.confirm_account.length === 0) {
      showAlert("Enter confirm beneficiary account number.");
    } else if (addUserForm.confirm_account !== addUserForm.account) {
      showAlert(
        "Enter beneficiary account number and confirm beneficiary account number are same"
      );
    } else if (addUserForm.ifsc.length === 0) {
      showAlert("Enter receiver bank's IFSC code.");
    } else if (addUserForm.bank_name.length === 0) {
      showAlert("Enter bank name");
    } else {
      console.log("Initiate transfer");
      setIsStoring(true);
      await dispatch(
        addBeneficiaryRequest(
          {
            userId: userData.id,
            addedBy: localStorage.getItem("userId"),
            bankName: addUserForm.bank_name,
            name: addUserForm.recipient_name,
            account: addUserForm.account,
            ifsc: addUserForm.ifsc,
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          let emptyvals = {
            recipient_name: "",
            account: "",
            confirm_account: "",
            ifsc: "",
            bank_name: "",
          };
          setAddUserForm(emptyvals);
          showAlert(response.message);
          props.onClose();
        }
      });
    }
  };

  return (
    <div>
      {isStoring && (
        <RingLoadingHudPopUp />
      )}
      {
        isAlertView && (
          <AlertView
          message={alertMessage}
          onClose = {()=> {
            setIsAlertView(false);
          }}
        />
        )
      }
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-10 card-1 p-5">
            <h3 className="text-center table">Add Beneficiary</h3>

            <div className="col-md-12">
              <div className="form-group">
                <label>Beneficiary name</label>
                <input
                  type="text"
                  name="recipient_name"
                  className="form-control"
                  value={addUserForm.recipient_name}
                  onChange={(e) => handleTranferChanges(e)}
                />
                <label>Beneficiary account number</label>
                <input
                  type="password"
                  name="account"
                  className="form-control"
                  value={addUserForm.account}
                  onChange={(e) => handleTranferChanges(e)}
                />
                <label>Confirm Beneficiary account number</label>
                <input
                  type="number"
                  name="confirm_account"
                  className="form-control"
                  value={addUserForm.confirm_account}
                  onChange={(e) => handleTranferChanges(e)}
                />
                <label>Beneficiary IFSC Code</label>
                <input
                  type="text"
                  name="ifsc"
                  className="form-control"
                  value={addUserForm.ifsc}
                  onChange={(e) => handleTranferChanges(e)}
                />
                <label>Bank Name</label>
                <input
                  type="text"
                  name="bank_name"
                  className="form-control"
                  value={addUserForm.bank_name}
                  onChange={(e) => handleTranferChanges(e)}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    style={{ display: "flex", justifyContent: "center" }}
                    type="button"
                    className="btn btn-purple-bg"
                    onClick={() => addNewBeneficiary()}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-white-bg"
                    onClick={() => {
                      if (props.onClose) props.onClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBeneficiary;

import React from 'react';

const Transactions = () => {
    return (
        <div className="container pt-2">
            <div className="row justify-content-around m-5">
                <h1>Transactions page</h1>
            </div>
        </div>
    );
};

export default Transactions;
import { updatePanAPIService, payOutTransfer, getPrepaidOperators, getDTHOperators, payBillService, getBeneficiaryListSession, addBeneficiarySession } from "../../../api/bankingAPI";


const veryfyPanRequest = (data) => async (dispatch) => {
  const response = await dispatch(updatePanAPIService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const initiatePaymentRequest = (data) => async (dispatch) => {
  const response = await dispatch(payOutTransfer(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const getPrepaidOperatorsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getPrepaidOperators(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const getDTHOperatorsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getDTHOperators(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const payBillRequest = (data) => async (dispatch) => {
  const response = await dispatch(payBillService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const getBeneficiaryListRequest = (data) => async (dispatch) => {
  const response = await dispatch(getBeneficiaryListSession(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const addBeneficiaryRequest = (data) => async (dispatch) => {
  const response = await dispatch(addBeneficiarySession(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

export { veryfyPanRequest, initiatePaymentRequest, getPrepaidOperatorsRequest, getDTHOperatorsRequest, payBillRequest, getBeneficiaryListRequest, addBeneficiaryRequest };

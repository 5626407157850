import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const v_balanceAPI = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.VIRTUAL_BALANCE}?limit=${data.limit}&offset=${data.offset}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const v_balanceAPI_ByEntry = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.VIRTUAL_BALANCE}?limit=${data.limit}&offset=${data.offset}&type=${data.type}&entry=${data.entry}&vendorId=${data.vId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const v_balance_total = () => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.VIRTUAL_BALANCE_TOTAL}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const updateBalanceAPI = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.UPDATE_BALANCE}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const updatePayInLog = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.UPDATE_BALANCE}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const walletBalance = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.WALLET_BALANCE}?vendorId=${data.vId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

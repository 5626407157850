import { Navigate } from "react-router-dom";
import AuthService from '../utils/services/auth-service';

const Protected = ({children }) => {
  if (AuthService.isAuthenticated()) {
     return children;
  }
    return <Navigate to="/login" replace />;

 
};
export default Protected;
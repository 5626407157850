import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePayOutHandle } from "../store/actions/onDataResultAction";
import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from "react-bootstrap";
import RingLoadingHudPopUp from "./loadinghudpopup";

const PayMentStatus = (props) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showStatus, setStatus] = useState('');
    const [isStoring, setIsStoring] = useState(false);

    const onSectionSelect = (sectionId) => { };

    const navigateToPayOut = async () => {
        setShow(true)
        props.onClose();
        setIsStoring(true);
        await dispatch(
            updatePayOutHandle(dispatch)
        ).then((response) => {
            setIsStoring(false);
            if (response) {
                setStatus("Payout Success.");

            } else {
                setStatus("Payout Success.");
            }
        });
    }

    return (
        <>
            {isStoring && (
                <RingLoadingHudPopUp />
            )}
            <ToastContainer className="p-3" position={'top-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body>{showStatus}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-8 card-1 p-5">
                            <h5 className="text-center">Payment Status</h5>

                            <h3 className="text-center">{props.status}</h3>

                            <div className="col-md-12 text-center mt-4">
                                <button
                                    className="btn btn-white-bg"
                                    onClick={() => {
                                        if (props.onClose) props.onClose();
                                    }}
                                >
                                    OK
                                </button>
                                {props.status === "Success" && (
                                    <button className="btn btn-primary" onClick={() => navigateToPayOut()}>Payout</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PayMentStatus;
